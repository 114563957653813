"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserInitialImage = exports.getUserOnboardingStatus = exports.getAllUsers = exports.getUser = exports.getLatestTermsAndConditions = exports.getUserBookmarkedPosts = exports.getUserPosts = exports.getPosts = exports.getManagerUser = void 0;
exports.getManagerUser = `
  query GetManagerUser($input: GetManagerUserInput!) {
    getManagerUser(input: $input) {
      id
      firstname
      lastname
      username
      postCount
      bookmarkedPosts
    }
  }
`;
exports.getPosts = `
  query GetPosts($input: GetPostsInput!) {
    getPosts(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.getUserPosts = `
  query GetUserPosts($input: GetUserPostsInput!) {
    getUserPosts(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.getUserBookmarkedPosts = `
  query GetUserBookmarkedPosts($input: GetUserBookmarkedPostsInput!) {
    getUserBookmarkedPosts(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.getLatestTermsAndConditions = `
  query GetLatestTermsAndConditions {
    getLatestTermsAndConditions {
      id
      copy
      creator
      created
    }
  }
`;
exports.getUser = `
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      id
      firstname
      lastname
      email
      dateOfBirth
      username
      gender
      location
      interests
      receivePushNotification
      created
      updated
      termsAndConditions {
        id
        timestamp
      }
    }
  }
`;
exports.getAllUsers = `
  query GetAllUsers {
    getAllUsers {
      id
      firstname
      lastname
      email
      dateOfBirth
      username
      gender
      location
      interests
      created
      updated
      termsAndConditions {
        id
        timestamp
      }
      roles
    }
  }
`;
exports.getUserOnboardingStatus = `
  query GetUserOnboardingStatus($userId: ID!) {
    getUserOnboardingStatus(userId: $userId) {
      userId
      requiresDetails
      requiresInterests
      requiresTermsAndConditions
      requiresUsername
      tcId
      tcCopy
    }
  }
`;
exports.getUserInitialImage = `
  query GetUserInitialImage($userId: ID!) {
    getUserInitialImage(userId: $userId) {
      mimeType
      imageBase64
    }
  }
`;
