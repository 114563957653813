import React from "react";
import CreatePost from "../components/CreatePost";
import PostList from "../components/PostList";
import "./Create.css";

const Create: React.FC = () => {
  return (
    <div className="Create text-center">
      <h1>Create!</h1>
      <CreatePost></CreatePost>
      <h1>Posts!</h1>
      <PostList></PostList>
    </div>
  );
};

export default Create;
