import { createReducer } from '@reduxjs/toolkit';
import {
  resetAccessCommand,
  fetchIdentityIdCommand,
  fetchIdentityIdFailedEvent,
  fetchIdentityIdSucceededEvent,
  handleSignInCodeCommand,
  signInAndStoreSessionCommand,
  signInCommand,
  signInFailedEvent,
  signInSucceededEvent,
  signOutSucceededEvent,
  signUpCommand,
  userDoesNotExistEvent,
} from './access.slice';

export interface AccessTransientState {
  isSignInInProgress: boolean;
  isHandlingSignInCodeInProgress: boolean;
  emailAlreadyExists: boolean;
  userDoesNotExist: boolean;
  email: string | undefined;
  code: string | undefined;
  isFetchingIdentityIdInProgress: boolean;
}

const initialState: AccessTransientState = {
  isSignInInProgress: false,
  isHandlingSignInCodeInProgress: false,
  emailAlreadyExists: false,
  userDoesNotExist: false,
  email: undefined,
  code: undefined,
  isFetchingIdentityIdInProgress: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetAccessCommand, () => {
      return initialState;
    })
    .addCase(signUpCommand, (state, action) => {
      return {
        ...state,
        email: action.payload.email,
      };
    })
    .addCase(signInAndStoreSessionCommand, (state, action) => {
      return {
        ...state,
        // if the email already exits then this
        // isn't a sign up...
        emailAlreadyExists: !action.payload,
      };
    })
    .addCase(handleSignInCodeCommand, (state, action) => {
      return {
        ...state,
        isHandlingSignInCodeInProgress: true,
        code: action.payload,
      };
    })
    .addCase(signInCommand, (state, action) => {
      return {
        ...state,
        isSignInInProgress: true,
        userDoesNotExist: false,
        email: action.payload,
      };
    })
    .addCase(signInSucceededEvent, (state) => {
      return {
        ...state,
        isSignInInProgress: false,
      };
    })
    .addCase(userDoesNotExistEvent, (state) => {
      return {
        ...state,
        userDoesNotExist: true,
        isSignInInProgress: false,
      };
    })
    .addCase(signInFailedEvent, (state) => {
      return {
        ...state,
        isSignInInProgress: false,
      };
    })
    .addCase(signOutSucceededEvent, () => {
      return initialState;
    })
    .addCase(fetchIdentityIdCommand, (state) => {
      return {
        ...state,
        isFetchingIdentityIdInProgress: true,
      };
    })
    .addCase(fetchIdentityIdSucceededEvent, (state) => {
      return {
        ...state,
        isFetchingIdentityIdInProgress: false,
      };
    })
    .addCase(fetchIdentityIdFailedEvent, (state) => {
      return {
        ...state,
        isFetchingIdentityIdInProgress: false,
      };
    });
});

export default reducer;
