import { createReducer } from '@reduxjs/toolkit';
//import { OnboardingRouteName } from '../../navigation/OnboardingNavigator';
import {
  fetchUserOnboardingStatusSucceededEvent,
  getNextScreen,
  resetOnboardingCommand,
} from '../onboarding/onboarding.slice';

export interface OnboardingTransientState {
  onboardingJustComplete: boolean | undefined;
  nextScreen: string | undefined;
}

const initialState: OnboardingTransientState = {
  onboardingJustComplete: false,
  nextScreen: undefined,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetOnboardingCommand, () => {
      return initialState;
    })
    .addCase(fetchUserOnboardingStatusSucceededEvent, (state, { payload }) => {
      const nextScreen = getNextScreen(payload);
      let onboardingJustComplete = false;
      if (state.nextScreen === 'Interests' && nextScreen === undefined) {
        onboardingJustComplete = true;
      }
      return {
        ...state,
        nextScreen,
        onboardingJustComplete,
      };
    });
});

export default reducer;
