import "./TermsAndConditions.css";

import React, { FormEvent, useEffect, useState } from "react";

import { Form } from "react-bootstrap";

import { API } from "aws-amplify";

import { ITermsAndConditions, ITermsAndConditionsInput } from "@fourz/common";

import { useTypedFormFields } from "../../helpers/useTypedFormFields";

import LoaderButton from "../../components/LoaderButton";
import { useAccessContext } from "../../components/AccessContext";

import config from "../../config.json";

interface ITermsAndConditionsFormProps {
  id: string;
  copy: string;
}

const TermsAndConditions: React.FC = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [latest, setLatest] = useState<ITermsAndConditions | undefined>(
    undefined
  );
  
  const { userId } = useAccessContext();

  const { fields, changeHandler } =
    useTypedFormFields<ITermsAndConditionsFormProps>({
      id: "",
      copy: "",
    });

  useEffect(() => {
    const getLatestTermsAndConditions = (): void => {
      API.get(config.tc.ApiName, "/latest", {})
        .then((response) => {
          const { data } = response;

          fields.copy = data.copy;
          setLatest(data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    };

    getLatestTermsAndConditions();
  }, []);

  const createTermsAndConditions = (copy: string): void => {
    API.post(config.tc.ApiName, "", {
      body: {
        copy,
        creator: userId,
      },
    })
      .then((response) => {
        const { data } = response;

        fields.id = data.id;
        fields.copy = data.copy;
        setLatest(data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingForm(true);

    createTermsAndConditions(fields.copy);

    setIsSubmittingForm(false);
  };

  const validateCreateTermsAndConditionsForm = () => {
    return fields.copy.length > 0;
  };

  return (
    <div className="TermsAndConditions">
      <div className="container">
        <Form onSubmit={handleOnSubmitAsync}>
          <Form.Group controlId="id">
            <Form.Label>id</Form.Label>
            <Form.Control type="id" value={fields.id} disabled={true} />
          </Form.Group>
          <Form.Group controlId="copy">
            <Form.Label>copy</Form.Label>
            <Form.Control
              type="copy"
              as="textarea"
              rows={6}
              value={fields.copy}
              onChange={changeHandler}
            />
          </Form.Group>

          <LoaderButton
            size="lg"
            type="submit"
            variant="success"
            isLoading={isSubmittingForm}
            disabled={!validateCreateTermsAndConditionsForm()}
          >
            create
          </LoaderButton>
        </Form>
      </div>
    </div>
  );
};

export default TermsAndConditions;
