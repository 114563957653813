import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { PostModel } from '../../models/PostModel';

export interface DraftPostsState {
  drafts: any[];
}

const initialState: DraftPostsState = {
  drafts: [],
};

const draftPostsSlice = createSlice({
  name: 'draft-posts',
  initialState,
  reducers: {
    resetDraftPostsCommand: (): DraftPostsState => {
      return initialState;
    },

    saveNewDraftCommand: (
      state: DraftPostsState,
      { payload }: PayloadAction<any>,
    ): DraftPostsState => {
      return {
        drafts: [...state.drafts, payload],
      };
    },

    updateDraftCommand: (
      { drafts: currentDrafts }: DraftPostsState,
      {
        payload: { index, post },
      }: PayloadAction<{ index: number; post: any }>,
    ): DraftPostsState => {
      return {
        drafts: currentDrafts.map((draft, i) => {
          return i !== index ? draft : post;
        }),
      };
    },

    deleteDraftCommand: (
      state: DraftPostsState,
      action: PayloadAction<number>,
    ): DraftPostsState => {
      return {
        drafts: [
          ...state.drafts.slice(0, action.payload),
          ...state.drafts.slice(action.payload + 1),
        ],
      };
    },
  },
});

export const {
  resetDraftPostsCommand,
  saveNewDraftCommand,
  updateDraftCommand,
  deleteDraftCommand,
} = draftPostsSlice.actions;

export default draftPostsSlice.reducer;
