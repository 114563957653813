import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import { FourzImage, FourzImageType } from '../../models/FourzImage';

export interface ImageState {
  image: any;
  imageLoading: boolean;
  imageLoaded: boolean;
  errors: string[];
}

const initialState: ImageState = {
  image: undefined,
  imageLoading: false,
  imageLoaded: false,
  errors: [],
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    resetImageCommand: (): ImageState => {
      return initialState;
    },

    fetchUserInitialImageCommand: (
      state: ImageState,
      _: PayloadAction<string>
    ): ImageState => {
      return {
        ...state,
        imageLoading: true,
      };
    },

    fetchUserInitialImageSucceededEvent: (
      state: ImageState,
      { payload }: PayloadAction<string>
    ): ImageState => {
      return {
        ...state,
        image: { value: payload, type: "FourzImageType.Base64" },
        imageLoading: false,
        imageLoaded: true,
      };
    },

    fetchUserInitialImageFailedEvent: (
      state: ImageState,
      action: PayloadAction<string>
    ): ImageState => {
      return {
        ...state,
        imageLoading: false,
        errors: [...state.errors, action.payload],
      };
    },

    imageSelectedCommand: (
      state: ImageState,
      { payload }: PayloadAction<{}>
    ): ImageState => {
      return {
        ...state,
        image: payload,
      };
    },
  },
});

export const {
  resetImageCommand,
  imageSelectedCommand,
  fetchUserInitialImageCommand,
  fetchUserInitialImageSucceededEvent,
  fetchUserInitialImageFailedEvent,
} = imageSlice.actions;

export default imageSlice.reducer;
