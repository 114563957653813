import { PayloadAction } from '@reduxjs/toolkit';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';

import { RootState } from '../root.reducer';

import { IReduxDependencies } from '../IReduxDependencies';
import {
  fetchUserInitialImageCommand,
  fetchUserInitialImageFailedEvent,
  fetchUserInitialImageSucceededEvent,
} from './image.slice';

const fetchUserInitialImageCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  _: Observable<RootState>,
  { graphQlService }: IReduxDependencies,
) => {
  return action$.pipe(
    filter((action) => fetchUserInitialImageCommand.match(action)),

    mergeMap(({ payload }) => {
      return from(graphQlService.getUserInitialImageAsync(payload)).pipe(
        mergeMap((result) => {
          return of(fetchUserInitialImageSucceededEvent(result));
        }),
        catchError((error) => {
          console.log('error: ', error);
          return of(fetchUserInitialImageFailedEvent(JSON.stringify(error)));
        }),
      );
    }),
  );
};

export default combineEpics(fetchUserInitialImageCommandEpic$);
