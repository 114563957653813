import React from "react";
import "./Settings.css";

const Settings: React.FC = () => {
  return (
    <div className="Settings text-center">
      <h1>Settings!</h1>
    </div>
  );
};

export default Settings;
