import "./index.css";
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import { API, Auth, Storage } from "aws-amplify";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import config from "./config.json";

const oauth = {
  // Domain name //********.auth.us-east-1.amazoncognito.com
  // domain: 'fourz-spike.auth.eu-west-2.amazoncognito.com',
  // domain: 'ampe6a991dc-e6a991dc-dev.auth.eu-west-2.amazoncognito.com',
  // https://fourz-irh.auth.eu-west-2.amazoncognito.com/oauth2/idpresponse
  domain: `fourz-${config.core.Stage}.auth.${config.common.Region}.amazoncognito.com`,

  // Authorized scopes
  scope: [
    'phone',
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin',
  ],

  // Callback URL
  redirectSignIn: 'https://localhost:3001/authorize', // http://www.example.com/signin/ or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

  // Sign out URL
  redirectSignOut: 'https://localhost:3001', // 'http://www.example.com/signout/' or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  // Note that REFRESH token will only be generated when the responseType is code
  responseType: 'code',

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  }
};

Storage.configure({
  AWSS3: {
    bucket: config.core.S3BucketName,
    region: config.common.Region,
  },
});

Auth.configure({
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK'],
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_verification_mechanisms: ['EMAIL'],

  federationTarget: 'COGNITO_USER_POOLS',
  identityPoolId: config.core.IdentityPoolId,
  identityPoolRegion: config.common.Region,
  mandatorySignIn: false,
  oauth,
  region: config.common.Region,
  userPoolId: config.core.UserPoolId,
  userPoolWebClientId: config.core.AdminUserPoolClientId,
});

API.configure({
  endpoints: [
    {
      name: config.tc.ApiName,
      endpoint: config.tc.ApiEndpoint,
      region: config.common.Region,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: config.iu.ApiName,
      endpoint: config.iu.ApiEndpoint,
      region: config.common.Region,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="App">
        <App />
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
