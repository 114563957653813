import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CurrentNavigator = 'Access' | 'Modal' | 'Onboarding';

export interface BranchState {
  contentLinkFromBranch: string | undefined;
  currentNavigator: CurrentNavigator | undefined;
}

const initialState: BranchState = {
  contentLinkFromBranch: undefined,
  currentNavigator: undefined,
};

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    resetBranchCommand: (): BranchState => {
      return initialState;
    },

    cacheContentLinkFromBranchCommand: (
      state: BranchState,
      { payload }: PayloadAction<string>,
    ): BranchState => {
      return {
        ...state,
        contentLinkFromBranch: payload,
      };
    },
    setCurrentNavigatorCommand: (
      state: BranchState,
      { payload }: PayloadAction<CurrentNavigator>,
    ): BranchState => {
      return {
        ...state,
        currentNavigator: payload,
      };
    },
    // deleteCachedContentLinkFromBranchCommand: (
    //   state: BranchState,
    // ): BranchState => {
    //   return {
    //     ...state,
    //   };
    // },
  },
});

export const {
  resetBranchCommand,
  cacheContentLinkFromBranchCommand,
  setCurrentNavigatorCommand,
} = branchSlice.actions;

export default branchSlice.reducer;
