import { combineReducers } from '@reduxjs/toolkit';

import accessSlice from './access/access.slice';
import accessTransientReducer from './access/access.transient.reducer';
import interestSlice from './profile/interest.slice';
import createPostSlice from './post/create-post.slice';
import draftPostsSlice from './post/draft-posts.slice';
import imageSlice from './profile/image.slice';
import onboardingSlice from './onboarding/onboarding.slice';
import onboardingTransientReducer from './onboarding/onboarding.transient.reducer';
import branchSlice from './branch/branch.slice';
import feedSlice from './feed/feed.slice';

export const rootReducer = combineReducers({
  accessState: accessSlice,
  accessTransientState: accessTransientReducer,
  createPostState: createPostSlice,
  draftPostsState: draftPostsSlice,
  interestState: interestSlice,
  imageState: imageSlice,
  onboardingState: onboardingSlice,
  onboardingTransientState: onboardingTransientReducer,
  branchState: branchSlice,
  feedState: feedSlice
});

export type RootState = ReturnType<typeof rootReducer>;
