import { IAmplifyAuthAdapter } from '@offcross/clients-common';
import { CognitoRefreshToken, CognitoUser } from 'amazon-cognito-identity-js';
import {
  Auth,
  CognitoHostedUIIdentityProvider,
  SignUpParams,
} from '@aws-amplify/auth';

const authAdapter: IAmplifyAuthAdapter = {
  authCreateCognitoUser: (username: string) =>
    (Auth as any).createCognitoUser(username),

  authSignInAsync: (email: string) => Auth.signIn(email),

  authSendCustomChallengeAnswerAsync: (user: CognitoUser, code: string) =>
    Auth.sendCustomChallengeAnswer(user, code),

  authSignUpAsync: (signUpParams: SignUpParams) => Auth.signUp(signUpParams),
  authFederatedSignInAsync: (provider: string) => {
    switch (provider) {
      case 'Facebook':
        return Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Facebook,
        });

      default:
        throw Error();
    }
  },
  authCurrentAuthenticatedUserAsync: () => Auth.currentAuthenticatedUser(),
  authCurrentSessionAsync: () => Auth.currentSession(),
  authSignOutAsync: () => Auth.signOut(),
  cognitoRefreshTokenBuilder: (refreshToken: string) =>
    new CognitoRefreshToken({ RefreshToken: refreshToken }),
};

export { authAdapter };
