import AWS, { AWSError } from 'aws-sdk';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import { IAwsConfig } from '@offcross/clients-common';
import { ICognitoUser } from './models/ICognitoUser';
import { ISessionData } from './models/ISessionData';
import { IAmplifyAuthAdapter } from './IAmplifyAuthAdapter';
import { ICredentials } from './models/ICredentials';
import { IFederatedSignIn } from './models/IFederatedSignIn';

export class AccessManager {
  constructor(
    private amplifyAuthAdapter: IAmplifyAuthAdapter,
    private awsConfig: IAwsConfig,
  ) {}

  public signInAsync = async (email: string): Promise<ICognitoUser> => {
    const user = await this.amplifyAuthAdapter.authSignInAsync(email);
    return this.dehydrateContigoUser(user);
  };

  public federatedSignInAsync = async (
    provider: string,
  ): Promise<ICredentials> => {
    const creds = await this.amplifyAuthAdapter.authFederatedSignInAsync(
      provider,
    );
    return creds;
  };

  public handleFederatedSignInCodeAsync = async (
    _: string,
  ): Promise<IFederatedSignIn | undefined> => {
    //console.log('In handleFederatedSignInCodeAsync.');
    //console.log('code:', _);

    const cognitoUser =
      await this.amplifyAuthAdapter.authCurrentAuthenticatedUserAsync();
    //console.log('cognitoUser:', cognitoUser);

    const signInUserSession = cognitoUser.getSignInUserSession();
    //console.log('signInUserSession:', signInUserSession);

    if (signInUserSession) {
      const session = this.extractSessionData(signInUserSession);
      //console.log('session:', session);

      const user = this.dehydrateContigoUser(cognitoUser);
      //console.log('user: ', user);

      return { user, session };
    }

    return undefined;
  };

  public sendCustomChallengeAnswerAsync = async (
    user: CognitoUser,
    code: string,
  ): Promise<ISessionData | undefined> => {
    await this.amplifyAuthAdapter.authSendCustomChallengeAnswerAsync(
      user,
      code,
    );

    const session = await this.amplifyAuthAdapter.authCurrentSessionAsync();

    return this.extractSessionData(session);
  };

  private extractSessionData = (session: CognitoUserSession): ISessionData => {
    const idToken = session.getIdToken();
    const accessToken = session.getAccessToken();
    const refreshToken = session.getRefreshToken();

    const { payload } = idToken;

    ////console.log("id token payload:", payload);

    const sub = payload['sub'];
    const preferredUsername = payload['preferred_username'];
    const groups = payload['cognito:groups'];

    return {
      sub: sub,
      preferredUsername: preferredUsername,
      roles: groups,
      idToken: idToken.getJwtToken(),
      accessToken: accessToken.getJwtToken(),
      refreshToken: refreshToken.getToken(),
    };
  };

  public refreshSessionAsync = async (
    user: ICognitoUser,
    refreshToken: string,
  ): Promise<ISessionData | undefined> => {
    const cognitoUser = this.rehydrateContigoUser(user);

    return new Promise<ISessionData>((resolve, reject) => {
      if (cognitoUser && refreshToken) {
        const cognitoRefreshToken =
          this.amplifyAuthAdapter.cognitoRefreshTokenBuilder(refreshToken);
        cognitoUser!.refreshSession(
          cognitoRefreshToken,
          (err?: any, session?: CognitoUserSession) => {
            if (err) {
              reject(err);
              return;
            }
            if (session) {
              const d = this.extractSessionData(session);
              resolve(d);
              return;
            }
          },
        );
      }
      return undefined;
    });
  };

  // following methods gratefully taken from
  // https://stackoverflow.com/questions/54847779/how-to-persist-cognitouser-during-signin-with-custom-auth-authentication-flow
  dehydrateContigoUser = (cognitoUser: CognitoUser): ICognitoUser => {
    // Session isn't exposed to TypeScript, but it's a public member in JS
    const session = (cognitoUser as any).Session;

    const CUSTOM_AUTH_TTL = 5 * 60 * 1000; // Milliseconds

    const expiresAt = Date.now() + CUSTOM_AUTH_TTL;
    const serialised: ICognitoUser = {
      session,
      expiresAt,
      username: cognitoUser.getUsername(),
    };
    return serialised;
  };

  public rehydrateContigoUser = (
    user: ICognitoUser,
  ): CognitoUser | undefined => {
    if (user) {
      const username = user.username;
      // Accessing private method of Auth here which is BAD, but it's still the
      // safest way to restore the custom auth session from local storage, as there
      // is no interface that lets us do it.
      // (If we created a new user pool object here instead to pass to a
      // CognitoUser constructor that would likely result in hard to catch bugs,
      // as Auth can assume that all CognitoUsers passed to it come from its pool
      // object.)
      const cognitoUser: CognitoUser =
        this.amplifyAuthAdapter.authCreateCognitoUser(username);
      // Session is not exposed to TypeScript, but it's a public member in the
      // JS code.
      (cognitoUser as any).Session = user.session;
      return cognitoUser;
    }
    return undefined;
  };

  public fetchIdentityIdAsync = (idToken: string): Promise<string> => {
    const cognitoIdentityCredentials =
      this.buildCognitoIdentityCredentials(idToken);

    return new Promise<string>((resolve, reject) => {
      cognitoIdentityCredentials.refresh((err?: AWSError) => {
        if (err) {
          reject(err);
          return;
        }

        const { identityId } = cognitoIdentityCredentials;
        resolve(identityId);
      });
    });
  };

  public clearCognitoIdentityCredentials = (idToken: string) => {
    const cognitoIdentityCredentials =
      this.buildCognitoIdentityCredentials(idToken);
    cognitoIdentityCredentials.clearCachedId();
  };

  buildCognitoIdentityCredentials = (
    idToken: string,
  ): AWS.CognitoIdentityCredentials => {
    AWS.config.region = this.awsConfig.region;
    const awsLogin = `cognito-idp.${this.awsConfig.region}.amazonaws.com/${this.awsConfig.userPoolId}`;

    var logins: Record<string, any> = {};
    logins[awsLogin] = idToken;

    const options = {
      IdentityPoolId: this.awsConfig.identityPoolId,
      Logins: logins,
    };

    const cognitoIdentityCredentials = new AWS.CognitoIdentityCredentials(
      options,
    );

    return cognitoIdentityCredentials;
  };
}
