import { combineEpics } from 'redux-observable';

import accessEpic from './access/access.epic';
import feedEpic from './feed/feed.epic';
import onboardingEpic from './onboarding/onboarding.epic';
import imageEpic from './profile/image.epic';

const rootEpic = combineEpics(accessEpic, feedEpic, onboardingEpic, imageEpic);

export { rootEpic };
