import {  Navigate } from "react-router-dom";
import { useAccessContext } from "./AccessContext";


const querystring = (name: string, url = window.location.href) => {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

interface IRedirectToRootWhenAuthenticatedProps {
  readonly children: any;
}

const RedirectToRootWhenAuthenticated: React.FC<
  IRedirectToRootWhenAuthenticatedProps
> = ({ children }) => {
  const { userId } = useAccessContext();
  const redirect = querystring("redirect");

  return !userId ? (
    children
  ) : (
    <Navigate to={redirect === "" || redirect === null ? "/" : redirect} />
  );
};

export default RedirectToRootWhenAuthenticated;
