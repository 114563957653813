import { Action } from "@reduxjs/toolkit";
import { Observable, from, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from "rxjs/operators";
import { combineEpics, Epic } from "redux-observable";

import { RootState } from "../root.reducer";

import {
  dataNotReadEvent,
  lastTokenReadForwardFalse,
  lastTokenReadForwardTrue,
  listNextPostsCommand,
  listPostsFailedEvent,
  listPostsSucceededReadForwardsFalseEvent,
  listPostsSucceededReadForwardsTrueEvent,
  listPreviousPostsCommand,
} from "./feed.slice";

import { IReduxDependencies } from "../IReduxDependencies";

const listNextPostsCommandEpic$: Epic = (
  action$: Observable<Action>,
  rootState$: Observable<RootState>,
  { graphQlService }: IReduxDependencies
) => {
  return action$.pipe(
    filter((action) => listNextPostsCommand.match(action)),

    withLatestFrom(rootState$),

    map(([_, state]) => {
      const {
        accessState: { userId },
        feedState: { tokens, limit, activeCall },
      } = state;

      const nextToken =
        tokens.length > 0 ? tokens[tokens.length - 1] : undefined;

      const canCall =
        activeCall === "next" && nextToken !== lastTokenReadForwardTrue;

      return {
        userId: userId!,
        limit,
        nextToken,
        canCall,
      };
    }),

    mergeMap(({ userId, limit, nextToken, canCall }) => {
      // if (canCall) {
      //   return from(
      //     graphQlService.listPostsAsync(userId, true, limit, nextToken),
      //   ).pipe(
      //     mergeMap((result) => {
      //       console.log('listPosts result: ', result);
      //       if (result.readForwards) {
      //         return of(listPostsSucceededReadForwardsTrueEvent(result));
      //       }
      //       return of(listPostsSucceededReadForwardsFalseEvent(result));
      //     }),
      //     catchError((error) => {
      //       console.log('error: ', error);
      //       return of(listPostsFailedEvent(JSON.stringify(error)));
      //     }),
      //   );
      // } else {
      return of(dataNotReadEvent());
      //}
    })
  );
};

const listPreviousPostsCommandEpic$: Epic = (
  action$: Observable<Action>,
  rootState$: Observable<RootState>,
  { graphQlService }: IReduxDependencies
) => {
  return action$.pipe(
    filter((action) => listPreviousPostsCommand.match(action)),

    withLatestFrom(rootState$),

    map(([_, state]) => {
      const {
        accessState: { userId },
        feedState: { tokens, limit, activeCall },
      } = state;

      const nextToken = tokens.length > 0 ? tokens[0] : undefined;
      const canCall =
        activeCall === "prev" && nextToken !== lastTokenReadForwardFalse;

      console.log({ nextToken, canCall });

      return {
        userId: userId!,
        limit,
        nextToken,
        canCall,
      };
    }),

    mergeMap(({ userId, limit, nextToken, canCall }) => {
      // if (canCall) {
      //   return from(
      //     graphQlService.listPostsAsync(userId, false, limit, nextToken),
      //   ).pipe(
      //     mergeMap((result) => {
      //       if (result.readForwards) {
      //         return of(listPostsSucceededReadForwardsTrueEvent(result));
      //       }
      //       return of(listPostsSucceededReadForwardsFalseEvent(result));
      //     }),
      //     catchError((error) => {
      //       return of(listPostsFailedEvent(JSON.stringify(error)));
      //     }),
      //   );
      // } else {
      return of(dataNotReadEvent());
      //}
    })
  );
};

export default combineEpics(
  listNextPostsCommandEpic$,
  listPreviousPostsCommandEpic$
);
