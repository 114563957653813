import "./App.css";
import React, { useEffect, useRef } from "react";

import { Provider } from "react-redux";
// we reference persistStore so TS can find PersistGate
// eslint-disable-next-line
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { Auth, Hub } from "aws-amplify";

import { createAuthLink, AUTH_TYPE, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  //ApolloCache,
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";

//import { persistor, store } from "./redux/store";

import AppRoutes from "./AppRoutes";

import AccessContextProvider from "./components/AccessContext";
import NavBar from "./components/NavBar";
import TermsAndConditionsModal from "./components/TermsAndContitionsModal";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import config from "./config.json";

import { apolloClientFactory } from "./apolloClientFactory";
import { persistorFactory, storeFactory } from "./redux/store.factory";
import { authAdapter } from "./redux/authAdapter";
import { analyticsAdapter } from "./redux/analyticsAdapter";
import { GraphQlService } from "./redux/GraphQlService";
import { IAwsConfig } from "@offcross/clients-common";

const url = config.appSync.GraphQlApiUrl;
const region = config.common.Region;

const client = apolloClientFactory(url, region);

const awsConfig: IAwsConfig = {
  region: config.common.Region,
  userPoolId: config.core.UserPoolId,
  identityPoolId: config.core.IdentityPoolId,
};

const logRedux = false;
const store = storeFactory(
  authAdapter,
  analyticsAdapter,
  storage,
  awsConfig,
  new GraphQlService(client),
  logRedux,
);
const persistor = persistorFactory(store);



const App: React.FC = () => {
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("user signed in");
          //setIsLoggedIn(true);
          //console.log(JSON.stringify(data));
          break;
        case "signUp":
          console.log("user signed up");
          break;
        case "signOut":
          console.log("user signed out");
          //setIsLoggedIn(false);
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          // console.log('sif', `${data}`);
          // if (`${data}`.includes('Already+found+an+entry+for+username+')) {
          //   console.log('signin in again');
          //   Auth.federatedSignIn({
          //     provider: CognitoHostedUIIdentityProvider.Facebook,
          //   });
          // }

          break;
        // case 'customState_failure':
        //   if (`${data}`.includes('Already+found+an+entry+for+username+')) {
        //     console.log('signin in again');
        //     // Auth.federatedSignIn({
        //     //   provider: CognitoHostedUIIdentityProvider.Facebook,
        //     // });
        //   }
        //   break;
        case "configured":
          console.log("the Auth module is configured");
      }

      // switch (event) {
      //   case "signIn":
      //     setUser(data);
      //     break;
      //   case "signOut":
      //     setUser(null);
      //     break;
      //   case "customOAuthState":
      //     setCustomState(data);
      // }
    });

    // Auth.currentAuthenticatedUser()
    //   //.then((currentUser) => setUser(currentUser))
    //   .then((currentUser) => console.log("currentuser:", currentUser))
    //   .catch(() => console.log("Not signed in"));

    // Auth.currentSession()
    //   //.then((currentUser) => setUser(currentUser))
    //   .then((session) => console.log("session:", session))
    //   .catch(() => console.log("Not signed in"));

    return unsubscribe;
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AccessContextProvider>
            {/* <TermsAndConditionsModal /> */}
            <NavBar />
            <div className="container py-4">
              <AppRoutes />
            </div>
          </AccessContextProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
