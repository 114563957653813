import { Auth } from "aws-amplify";

import { createAuthLink, AUTH_TYPE, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  //ApolloCache,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
//import { PostType, PostTypeConnection } from "@fourz/appsync-client";

// const postTypeConnectionBuilder = (
//   nextToken: string,
//   posts: PostType[],
//   readForwards: boolean
// ): PostTypeConnection => {
//   return {
//     __typename: "PostTypeConnection",
//     nextToken,
//     posts,
//     readForwards,
//   };
// };

// const mergePostTypeConnection = (
//   existing: PostTypeConnection | undefined,
//   incoming: PostTypeConnection | undefined
// ): PostTypeConnection | undefined => {
//   // console.log("incoming: ", incoming);
//   if (incoming) {
//     if (incoming.posts) {
//       const {
//         posts: incomingPosts,
//         nextToken: incomingNextToken,
//         readForwards,
//       } = incoming;

//       const newPostCount = incomingPosts.length;

//       let cachedPosts: PostType[] = [];
//       let cachedTokens = [];

//       if (existing) {
//         const { posts: existingPosts, nextToken: existingNextTokens } =
//           existing;

//         if (existingPosts && existingNextTokens) {
//           cachedTokens = existingNextTokens.split("|");
//           // console.log("cachedTokens from string: ", cachedTokens);

//           const indexOfFirstGap = cachedTokens.indexOf("");

//           const cachedTokensWithIndexes = cachedTokens.map((t: string) =>
//             t.split(":")
//           );

//           if (indexOfFirstGap > -1) {
//             const nextIndex =
//               parseInt(cachedTokensWithIndexes[indexOfFirstGap - 1][0]) + 1;

//             if (readForwards) {
//               cachedTokens[
//                 indexOfFirstGap
//               ] = `${nextIndex}:${incomingNextToken}`;
//               cachedPosts = [...existingPosts, ...incomingPosts];
//             } else {
//               // cachedTokens = Array.from({
//               //   ...[incomingNextToken, ...cachedTokens],
//               //   length: 3,
//               // });
//               // cachedPosts = [...incomingPosts, ...existingPosts];

//               // easy way out
//               throw Error(
//                 "You cant go back when there are only two pages in the cache. This error message is not really very good."
//               );
//             }
//           } else {

//             if (readForwards) {
//               const nextIndex = parseInt(cachedTokensWithIndexes[2][0]) + 1;
//               cachedTokens = [
//                 ...cachedTokens.slice(1),
//                 incomingNextToken
//                   ? `${nextIndex}:${incomingNextToken}`
//                   : undefined,
//               ];
//               cachedPosts = [
//                 ...existingPosts.slice(newPostCount),
//                 ...incomingPosts,
//               ];
//             } else {
//               const nextIndex = parseInt(cachedTokensWithIndexes[0][0]) - 1;
//               cachedTokens = [
//                 incomingNextToken
//                   ? `${nextIndex}:${incomingNextToken}`
//                   : undefined,
//                 ...cachedTokens.slice(0, -1),
//               ];

//               cachedPosts = [
//                 // the incoming data will have as its first value a post we already
//                 // have so we slice the array and discard the duplicate
//                 ...incomingPosts.slice(1),
//                 ...existingPosts.slice(0, -newPostCount),
//               ];
//             }
//           }
//           // console.log("cachedTokens to return: ", cachedTokens);
//           const r = postTypeConnectionBuilder(
//             cachedTokens.join("|"),
//             cachedPosts,
//             true
//           );

//           return r;
//         }
//       }
//     }

//     const r = postTypeConnectionBuilder(
//       [`0:${incoming.nextToken}`, undefined, undefined].join("|"),
//       incoming.posts ?? [],
//       incoming.readForwards
//     );
//     return r;
//   }
// };

const apolloClientFactory = (
  url: string,
  region: string
): ApolloClient<any> => {
  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const currentSession = await Auth.currentSession();
      const t = currentSession.getIdToken().getJwtToken() as string;
      return t;
    },
  };

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }),
  ]);

  const cache = new InMemoryCache({
    // typePolicies: {
    //   Query: {
    //     fields: {
    //       listPosts: {
    //         merge: mergePostTypeConnection,
    //         read(existing: PostTypeConnection | undefined) {
    //           if (existing) {
    //             if (existing.posts) {
    //               return postTypeConnectionBuilder(
    //                 existing.nextToken!,
    //                 [...existing.posts],
    //                 true
    //               );
    //             }
    //           }
    //         },
    //       },
    //     },
    //   },
    // },
  });

  const client = new ApolloClient({
    link,
    //cache: new InMemoryCache(),
    cache,
    headers: { Authorization: auth.jwtToken as string },
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  return client;
};

export { apolloClientFactory };
