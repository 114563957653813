import "./SignIn.css";
import React, { FormEvent, useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import { Alert, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useTypedFormFields } from "../../helpers/useTypedFormFields";

import LoaderButton from "../../components/LoaderButton";
import CheckEmail from "../../components/CheckEmail";
import { RootState } from "../../redux/root.reducer";
import {
  federatedSignInCommand,
  handleSignInCodeCommand,
  signInCommand,
} from "../../redux/access";

interface ISignInFormProps {
  email: string;
}

type ParamProps = {
  code?: string;
};

const SignIn: React.FC = () => {
  const { code } = useParams<ParamProps>();

  const dispatch = useDispatch();

  const {
    accessTransientState: {
      email: eml,
      isSignInInProgress,
      userDoesNotExist,
      isHandlingSignInCodeInProgress,
    },
    accessState: { hasSentEmail },
  } = useSelector((state: RootState) => state);

  const { fields, changeHandler } = useTypedFormFields<ISignInFormProps>({
    email: "",
  });

  useEffect(() => {
    if (code) {
      dispatch(handleSignInCodeCommand(code));
    }
  }, [code]);

  const handleSignInSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(signInCommand(fields.email));
  };

  const validateSignInForm = () => {
    return fields.email.length > 0;
  };

  const renderSignInForm = () => {
    return (
      <div className="container">
        <div className="col-sm">
          <Form onSubmit={handleSignInSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={fields.email}
                onChange={changeHandler}
              />
            </Form.Group>
            <LoaderButton
              //block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isSignInInProgress}
              disabled={!validateSignInForm()}
            >
              sign in
            </LoaderButton>
            {userDoesNotExist && (
              <Alert variant="warning">
                We could not find an account with that email address, click{" "}
                <a href="sign-up">here</a> to sign up.
              </Alert>
            )}
          </Form>
          <Button
            title="Facebook"
            onClick={() => {
              dispatch(federatedSignInCommand("Facebook"));
            }}
          >
            Facebook
          </Button>
        </div>
      </div>
    );
  };

  function renderCheckEmail(email: string) {
    return (
      <div className="SignUp">
        <CheckEmail showAlreadyExists={false} email={email} />
      </div>
    );
  }

  function renderCheckingEmail() {
    return (
      <div className="SignUp">
        <Alert variant="info">Verifying the code and logging you in.</Alert>
      </div>
    );
  }

  return (
    <div className="SignIn">
      {hasSentEmail
        ? isHandlingSignInCodeInProgress
          ? renderCheckingEmail()
          : renderCheckEmail(eml!)
        : renderSignInForm()}
    </div>
  );
};


export default SignIn;
