import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICognitoUser } from "./models/ICognitoUser";
import { ICredentials } from "./models/ICredentials";
import { IFederatedSignIn } from "./models/IFederatedSignIn";
import { ISessionData } from "./models/ISessionData";
import { ISignUp } from "./models/ISignUp";

export interface AccessState {
  cognitoUser: ICognitoUser | undefined;
  errors: string[];

  userId: string | undefined;
  roles: string[] | undefined;
  idToken: string | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  identityId: string | undefined;

  hasSentEmail: boolean;
  isSignUpInProgress: boolean;

  code: string | undefined;

  federatedProvider: string | undefined;
  isFederatedSignInInProgress: boolean;
  federatedCode: string | undefined;
  federatedCredentials: ICredentials | undefined;
}

const initialState: AccessState = {
  cognitoUser: undefined,
  errors: [],

  userId: undefined,
  idToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  roles: undefined,
  identityId: undefined,

  hasSentEmail: false,
  isSignUpInProgress: false,

  code: undefined,

  federatedProvider: undefined,
  isFederatedSignInInProgress: false,
  federatedCode: undefined,
  federatedCredentials: undefined,
};

const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    resetAccessCommand: (): AccessState => {
      return initialState;
    },

    signUpCommand: (
      state: AccessState,
      _action: PayloadAction<ISignUp>
    ): AccessState => {
      return {
        ...state,
        isSignUpInProgress: true,
      };
    },
    signInAndStoreSessionCommand: (
      state: AccessState,
      action: PayloadAction<boolean>
    ): AccessState => {
      return {
        ...state,
        isSignUpInProgress: action.payload,
      };
    },
    signInEmailSentEvent: (
      state: AccessState,
      action: PayloadAction<ICognitoUser>
    ): AccessState => {
      return {
        ...state,
        cognitoUser: action.payload,
        hasSentEmail: true,
      };
    },
    signUpFailedEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        isSignUpInProgress: false,
      };
    },
    handleSignInCodeCommand: (
      state: AccessState,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        code: action.payload,
      };
    },
    signInCommand: (
      state: AccessState,
      _action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
      };
    },

    signInSucceededEvent: (
      state: AccessState,
      action: PayloadAction<ISessionData>
    ): AccessState => {
      const {
        payload: {
          preferredUsername,
          roles,
          idToken,
          accessToken,
          refreshToken,
        },
      } = action;

      return {
        ...state,
        userId: preferredUsername,
        roles: roles,
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
        isSignUpInProgress: false,
      };
    },

    userDoesNotExistEvent: (state: AccessState): AccessState => {
      return {
        ...state,
      };
    },

    signInFailedEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    },

    signOutCommand: (state: AccessState): AccessState => {
      return {
        ...state,
      };
    },
    signOutSucceededEvent: (): AccessState => {
      return initialState;
    },
    signOutFailedEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    },

    fetchIdentityIdCommand: (state: AccessState): AccessState => {
      return {
        ...state,
      };
    },
    fetchIdentityIdSucceededEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        identityId: action.payload,
      };
    },
    fetchIdentityIdFailedEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    },

    updateUserIdentityIdCommand: (
      state: AccessState,
      _action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
      };
    },
    updateUserIdentityIdSucceededEvent: (
      state: AccessState,
      _action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        //identityId: action.payload,
      };
    },
    updateUserIdentityIdFailedEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    },

    clearCognitoIdentityCredentialsCommand: (
      state: AccessState
    ): AccessState => {
      return {
        ...state,
      };
    },
    cognitoIdentityCredentialsClearedEvent: (
      state: AccessState
    ): AccessState => {
      return {
        ...state,
      };
    },

    refreshSessionCommand: (state: AccessState): AccessState => {
      return {
        ...state,
      };
    },
    refreshSessionSucceededEvent: (
      state: AccessState,
      action: PayloadAction<ISessionData>
    ): AccessState => {
      const {
        payload: { roles, idToken, accessToken, refreshToken },
      } = action;

      return {
        ...state,
        roles: roles,
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
      };
    },
    refreshSessionFailedEvent: (
      state: AccessState,
      action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    },

    analyticsUpateEndpointCommand: (
      state: AccessState,
      _action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
      };
    },

    analyticsUpateEndpointSucceededEvent: (state: AccessState): AccessState => {
      return {
        ...state,
      };
    },

    analyticsUpateEndpointFailedEvent: (
      state: AccessState,
      _action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
      };
    },

    federatedSignInCommand: (
      state: AccessState,
      { payload }: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
        federatedProvider: payload,
        isFederatedSignInInProgress: true,
      };
    },
    federatedSignInSucceededEvent: (
      state: AccessState,
      { payload }: PayloadAction<ICredentials>
    ): AccessState => {
      return {
        ...state,
        federatedCredentials: payload,
      };
    },
    federatedSignInFailedEvent: (
      state: AccessState,
      _action: PayloadAction<string>
    ): AccessState => {
      return {
        ...state,
      };
    },
    handleFederatedSignInCodeCommand: (
      state: AccessState,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        federatedCode: action.payload,
        //isFederatedSignInInProgress: false
      };
    },
    federatedSignInCompleteCommand: (
      state: AccessState,
      action: PayloadAction<IFederatedSignIn>
    ) => {
      const {
        payload: {
          session: {
            preferredUsername,
            roles,
            idToken,
            accessToken,
            refreshToken,
          },
          user,
        },
      } = action;

      return {
        ...state,
        userId: preferredUsername,
        roles: roles,
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
        isFederatedSignInInProgress: false,
        cognitoUser: user,
      };
    },
  },
});

export const {
  resetAccessCommand,

  signUpCommand,
  signInAndStoreSessionCommand,
  signInEmailSentEvent,
  signUpFailedEvent,

  handleSignInCodeCommand,
  handleFederatedSignInCodeCommand,

  signInCommand,
  signInSucceededEvent,
  userDoesNotExistEvent,
  signInFailedEvent,

  federatedSignInCommand,
  federatedSignInSucceededEvent,
  federatedSignInFailedEvent,
  federatedSignInCompleteCommand,

  signOutCommand,
  signOutSucceededEvent,
  signOutFailedEvent,

  fetchIdentityIdCommand,
  fetchIdentityIdSucceededEvent,
  fetchIdentityIdFailedEvent,

  updateUserIdentityIdCommand,
  updateUserIdentityIdSucceededEvent,
  updateUserIdentityIdFailedEvent,

  clearCognitoIdentityCredentialsCommand,
  cognitoIdentityCredentialsClearedEvent,

  refreshSessionCommand,
  refreshSessionSucceededEvent,
  refreshSessionFailedEvent,

  analyticsUpateEndpointCommand,
  analyticsUpateEndpointSucceededEvent,
  analyticsUpateEndpointFailedEvent,
} = accessSlice.actions;

export default accessSlice.reducer;
