import { ApolloClient, gql } from "@apollo/client";
import {
  getUserInitialImage,
  GetUserInitialImageQuery,
  GetUserInitialImageQueryVariables,
  getUserOnboardingStatus,
  GetUserOnboardingStatusQuery,
  GetUserOnboardingStatusQueryVariables,
  updateUserIdentityId,
  UpdateUserIdentityIdMutation,
  UpdateUserIdentityIdMutationVariables,
  // listPosts,
  // ListPostsQuery,
  // ListPostsQueryVariables,
  // PostTypeConnection,
} from "@fourz/appsync-client";
import { LocalUserOnboardingStatusType } from "./onboarding/onboarding.slice";

interface IGraphQlService {
  getUserOnboardingStatusAsync: (
    userId: string
  ) => Promise<LocalUserOnboardingStatusType>;

  getUserInitialImageAsync: (userId: string) => Promise<string>;

  // listPostsAsync: (
  //   userId: string,
  //   readForwards: boolean,
  //   limit: number,
  //   nextToken?: string
  // ) => Promise<any>;

  updateUserIdentityIdAsync: (
    userId: string,
    identityId: string
  ) => Promise<string>;
}

class GraphQlService implements IGraphQlService {
  constructor(private client: ApolloClient<any>) {}

  public getUserOnboardingStatusAsync = async (
    userId: string
  ): Promise<LocalUserOnboardingStatusType> => {
    //console.log(userId);

    const variables: GetUserOnboardingStatusQueryVariables = {
      userId: userId!,
    };

    const {
      data: { getUserOnboardingStatus: result },
    } = await this.client.query<
      GetUserOnboardingStatusQuery,
      GetUserOnboardingStatusQueryVariables
    >({ query: gql(getUserOnboardingStatus), variables });

    return result ?? null;
  };

  public getUserInitialImageAsync = async (userId: string): Promise<string> => {
    const variables: GetUserInitialImageQueryVariables = {
      userId: userId!,
    };

    const {
      data: { getUserInitialImage: result },
    } = await this.client.query<
      GetUserInitialImageQuery,
      GetUserInitialImageQueryVariables
    >({ query: gql(getUserInitialImage), variables });

    return result.imageBase64;
  };

  //   // this query bypasses the Apollo cache, we have a more
  //   // sophisticated cache in redux to handle the feed use case
  //   public listPostsAsync = async (
  //     userId: string,
  //     readForwards: boolean,
  //     limit: number,
  //     nextToken?: string,
  //   ): Promise<PostTypeConnection> => {
  //     const variables: ListPostsQueryVariables = {
  //       input: { userId: userId!, limit, readForwards, nextToken },
  //     };

  //     console.log('variables: ', variables);

  //     const {
  //       data: { listPosts: result },
  //     } = await this.client.query<ListPostsQuery, ListPostsQueryVariables>({
  //       query: gql(listPosts),
  //       variables,
  //       fetchPolicy: 'no-cache',
  //     });

  //     return result;
  //   };

  public updateUserIdentityIdAsync = async (
    userId: string,
    identityId: string
  ): Promise<string> => {
    const variables: UpdateUserIdentityIdMutationVariables = {
      input: {
        userId,
        identityId,
      },
    };

    const { data } = await this.client.mutate<
      UpdateUserIdentityIdMutation,
      UpdateUserIdentityIdMutationVariables
    >({ mutation: gql(updateUserIdentityId), variables });

    return data?.updateUserIdentityId ?? "";
  };
}

export type { IGraphQlService };
export { GraphQlService };
