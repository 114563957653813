import React from "react";
import "./NotFound.css";

const NotFound: React.FC = () => {
  return (
    <div className="NotFound text-center">
      <h3>Sorry, page not found!</h3>
    </div>
  );
};

export default NotFound;
