import "./PostList.css";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  bookmarkPost,
  BookmarkPostMutation,
  BookmarkPostMutationVariables,
  deleteBookmark,
  DeleteBookmarkMutation,
  DeleteBookmarkMutationVariables,
  deleteRating,
  DeleteRatingMutation,
  DeleteRatingMutationVariables,
  // deleteRating,
  // DeleteRatingMutation,
  // DeleteRatingMutationVariables,
  getPosts,
  GetPostsQuery,
  GetPostsQueryVariables,
  PostType,
  ratePost,
  RatePostMutation,
  RatePostMutationVariables,
} from "@fourz/appsync-client";
import { useAccessContext } from "./AccessContext";

const PostList: React.FC = () => {
  const { userId } = useAccessContext();
  const [posts, setPosts] = useState<PostType[] | undefined>(undefined);
  const limit = 2;

  const { error } = useQuery<GetPostsQuery, GetPostsQueryVariables>(
    gql(getPosts),
    {
      variables: {
        input: {
          userId: userId!,
        },
      },
      onCompleted: (d) => {
        console.log("ListPostQuery response: ", d);
        setPosts(d.getPosts ?? []);
      },
    }
  );

  const [deleteRatingAsync, { error: e, data: d }] = useMutation<
    DeleteRatingMutation,
    DeleteRatingMutationVariables
  >(gql(deleteRating));

  const [ratePostAsync, { error: ee, data: dd }] = useMutation<
    RatePostMutation,
    RatePostMutationVariables
  >(gql(ratePost));

  const [bookmarkPostAsync, { error: eee, data: ddd }] = useMutation<
    BookmarkPostMutation,
    BookmarkPostMutationVariables
  >(gql(bookmarkPost));

  const [deleteBookmarkAsync, { error: eeee, data: dddd }] = useMutation<
    DeleteBookmarkMutation,
    DeleteBookmarkMutationVariables
  >(gql(deleteBookmark));
  // const s = {
  //   display: 'block',
  //   width: '150px',
  //   word-wrap: 'break-word'

  // };

  // const fetchMorePosts = async (
  //   readForwards: boolean,
  //   nextTokensString: string
  // ): Promise<any> => {
  //   // there are always three items in this string array
  //   // some of the slots might be empty though
  //   const nextTokenArray = nextTokensString.split("|");

  //    const indexOfFirstGap = cachedTokens.indexOf("");

  //    // ggot to here with adding indexes then gave up bcase brin stopped

  //   const nextToken = readForwards
  //     ? nextTokenArray[2]
  //     : nextTokenArray[0];

  //   if (nextToken) {
  //     return await fetchMore({
  //       variables: {
  //         input: {
  //           userId: userId!,
  //           // when we read the previous page it returns a value we
  //           // already have as its first entry, we strip that dupe
  //           // in the cache, to make up for it we extend the limit here
  //           // two bits of code to hande that that are miles apart, meh
  //           limit: readForwards ? limit : limit + 1,
  //           nextToken,
  //           readForwards,
  //         },
  //       },
  //     });
  //   }

  //   //return new Promise<void>();
  // };

  const s = {
    display: "block",
    width: "1300px",
    "word-wrap": "break-word",
  };

  return (
    <div className="PostList">
      <div className="container">
        <span>Posts</span>

        {/* <div>
          <span style={s}>{data?.listPosts?.nextToken}</span>
        </div> */}

        {posts ? (
          <>
            <table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>subject</th>
                  <th>user has rates</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {posts.map(
                  ({
                    id,
                    userHasRated,
                    ownerId,
                    subject,
                    type,
                    userHasBookmarked,
                  }) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{subject}</td>
                      <td>{userHasRated ? "yes!" : "no!"}</td>
                      <td>
                        {userHasBookmarked ? (
                          <Button
                            onClick={async () =>
                              await deleteBookmarkAsync({
                                variables: {
                                  input: {
                                    userId: userId!,
                                    type: type!,
                                    postId: id,
                                  },
                                },
                              })
                            }
                          >
                            delete bookmark
                          </Button>
                        ) : (
                          <Button
                            onClick={async () =>
                              await bookmarkPostAsync({
                                variables: {
                                  input: {
                                    userId: userId!,
                                    type: type!,
                                    postId: id,
                                  },
                                },
                              })
                            }
                          >
                            bookmark post
                          </Button>
                        )}
                      </td>
                      <td>
                        {userHasRated ? (
                          <Button
                            onClick={async () =>
                              await deleteRatingAsync({
                                variables: {
                                  input: {
                                    userId: userId!,
                                    type: type!,
                                    postId: id,
                                  },
                                },
                              })
                            }
                          >
                            delete rating
                          </Button>
                        ) : (
                          <Button
                            onClick={async () =>
                              await ratePostAsync({
                                variables: {
                                  input: {
                                    userId: userId!,
                                    postId: id,
                                    type: type!,
                                    rating: ["tl", "tr", "bl", "br"],
                                  },
                                },
                              })
                            }
                          >
                            rate post
                          </Button>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {/* <Button
              onClick={async () => {
                // await fetchMore({
                //   variables: {
                //     input: {
                //       userId: userId!,
                //       limit,
                //       nextToken: data?.listPosts?.nextToken,
                //       readForwards: false,
                //     },
                //   },
                // })
                // await fetchMorePosts(false, data.listPosts.nextToken!)
              }}
            >
              fetch previous
            </Button>
            <Button
              onClick={async () => {
                // await fetchMore({
                //   variables: {
                //     input: {
                //       userId: userId!,
                //       limit,
                //       nextToken: data?.listPosts?.nextToken,
                //       readForwards: true,
                //     },
                //   },
                // })
                //  await fetchMorePosts(true, data.listPosts.nextToken!)
              }}
            >
              fetch next
            </Button> */}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PostList;
