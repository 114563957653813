import {
  ConfigureStoreOptions,
  EnhancedStore,
  configureStore,
} from "@reduxjs/toolkit";
import {
  PERSIST,
  PersistConfig,
  Persistor,
  REGISTER,
  Storage,
  persistReducer,
  persistStore,
} from "redux-persist";

// @ts-ignore
import { createLogger } from "redux-logger";

import { createEpicMiddleware, Epic } from "redux-observable";

import { rootEpic } from "./root.epic";
import { IAmplifyAuthAdapter } from "./access/IAmplifyAuthAdapter";

import { IAnalyticsAdapter } from "@offcross/clients-common";

import { RootState, rootReducer } from "./root.reducer";
import { IAwsConfig } from "@offcross/clients-common";
import { IReduxDependencies } from "./IReduxDependencies";

import { IGraphQlService } from "./GraphQlService";

const storeFactory = (
  amplifyAuthAdapter: IAmplifyAuthAdapter,
  analyticsAdapter: IAnalyticsAdapter,
  storage: Storage,
  awsConfig: IAwsConfig,
  graphQlService: IGraphQlService,
  useLogger: boolean = false
): EnhancedStore<RootState> => {
  const reduxDependencies: IReduxDependencies = {
    amplifyAuthAdapter: amplifyAuthAdapter,
    awsConfig: awsConfig,
    analyticsAdapter: analyticsAdapter,
    storage: storage,
    graphQlService: graphQlService,
  };

  const epicMiddleware = createEpicMiddleware({
    dependencies: reduxDependencies,
  });

  const persistConfig: PersistConfig<RootState> = {
    key: "root",
    storage: storage,
    blacklist: [
      "accessTransientState",
      "interestState",
      "onboardingTransientState",
      "branchState",
    ],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const options = {
    serializableCheck: {
      ignoredActions: [REGISTER, PERSIST],
    },
  };

  const configureStoreOptions: ConfigureStoreOptions = {
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      useLogger
        ? getDefaultMiddleware(options)
            .concat(epicMiddleware)
            .concat(createLogger())
        : getDefaultMiddleware(options).concat(epicMiddleware),
  };

  const store = configureStore(configureStoreOptions);

  epicMiddleware.run(rootEpic as Epic);

  return store;
};

const persistorFactory = (store: EnhancedStore<RootState>): Persistor => {
  return persistStore(store, null, () => {
    store.dispatch({ type: "bootstrap" });
  });
};

export { storeFactory, persistorFactory };
