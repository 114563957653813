"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserReceivePushNotification = exports.updateUsername = exports.updateUserInterests = exports.updateUserDetails = exports.updateUserIdentityId = exports.acceptTermsAndConditions = exports.deleteBookmark = exports.bookmarkPost = exports.deleteRating = exports.ratePost = exports.createPost = void 0;
exports.createPost = `
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input)
  }
`;
exports.ratePost = `
  mutation RatePost($input: RatePostInput!) {
    ratePost(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.deleteRating = `
  mutation DeleteRating($input: SpecificPostInput!) {
    deleteRating(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.bookmarkPost = `
  mutation BookmarkPost($input: SpecificPostInput!) {
    bookmarkPost(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.deleteBookmark = `
  mutation DeleteBookmark($input: SpecificPostInput!) {
    deleteBookmark(input: $input) {
      id
      ownerId
      ownerIdentityId
      subject
      topLeftTotal
      topRightTotal
      bottomLeftTotal
      bottomRightTotal
      ratingsCount
      raters {
        ratingUserId
        rating
        timestamp
      }
      userHasRated
      userHasBookmarked
      created
      updated
      type
    }
  }
`;
exports.acceptTermsAndConditions = `
  mutation AcceptTermsAndConditions($input: AcceptTermsAndConditionsInput!) {
    acceptTermsAndConditions(input: $input)
  }
`;
exports.updateUserIdentityId = `
  mutation UpdateUserIdentityId($input: UpdateUserIdentityIdInput!) {
    updateUserIdentityId(input: $input)
  }
`;
exports.updateUserDetails = `
  mutation UpdateUserDetails($input: UpdateUserDetailsInput!) {
    updateUserDetails(input: $input)
  }
`;
exports.updateUserInterests = `
  mutation UpdateUserInterests($input: UpdateUserInterestsInput!) {
    updateUserInterests(input: $input)
  }
`;
exports.updateUsername = `
  mutation UpdateUsername($input: UpdateUserUsernameInput!) {
    updateUsername(input: $input) {
      userId
      success
      message
      suggestions
    }
  }
`;
exports.updateUserReceivePushNotification = `
  mutation UpdateUserReceivePushNotification(
    $input: UpdateUserReceivePushNotificationInput!
  ) {
    updateUserReceivePushNotification(input: $input) {
      success
      message
    }
  }
`;
