import { UserOnboardingStatusType } from '@fourz/appsync-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { OnboardingRouteName } from '../../navigation/OnboardingNavigator';

// we usually use 'undefined' everywhere but we make an exception here
// becase appsync returns 'null' instead if there isn't a value to return
// for the UserOnboardingStatusType, we compare this in the
// fetchUserOnboardingStatusCommandEpic$ so is needs to be the same 'non type'
export type LocalUserOnboardingStatusType = UserOnboardingStatusType | null;

export interface OnboardingState {
  errors: string[];
  userOnboardingStatus: LocalUserOnboardingStatusType;
  showOnboarding: boolean | undefined;
  nextScreen: string | undefined;
}

const initialState: OnboardingState = {
  errors: [],
  userOnboardingStatus: null,
  showOnboarding: undefined,
  nextScreen: undefined,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    resetOnboardingCommand: (): OnboardingState => {
      return initialState;
    },
    fetchUserOnboardingStatusCommand: (
      state: OnboardingState,
    ): OnboardingState => {
      return {
        ...state,
      };
    },
    fetchUserOnboardingStatusSucceededEvent: (
      state: OnboardingState,
      { payload }: PayloadAction<LocalUserOnboardingStatusType>,
    ): OnboardingState => {
      return {
        ...state,
        userOnboardingStatus: payload,
        showOnboarding: getShowOnboarding(payload),
        nextScreen: getNextScreen(payload),
      };
    },
    fetchUserOnboardingStatusFailedEvent: (
      state: OnboardingState,
      action: PayloadAction<string>,
    ): OnboardingState => {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    },
  },
});

export const getNextScreen = (
  type: LocalUserOnboardingStatusType,
): string | undefined => {
  if (type) {
    if (type.requiresTermsAndConditions) {
      return 'TermsAndConditions';
    }
    if (type.requiresDetails) {
      return 'Details';
    }
    if (type.requiresUsername) {
      return 'Username';
    }
    if (type.requiresInterests) {
      return 'Interests';
    }
  }
  return undefined;
};

const getShowOnboarding = (type: LocalUserOnboardingStatusType): boolean => {
  if (type) {
    const {
      requiresDetails,
      requiresInterests,
      requiresTermsAndConditions,
      requiresUsername,
    } = type;

    const all = [
      requiresDetails,
      requiresInterests,
      requiresTermsAndConditions,
      requiresUsername,
    ];
    const b = all.includes(true);
    return b;
  }
  return true;
};

export const {
  resetOnboardingCommand,
  fetchUserOnboardingStatusCommand,
  fetchUserOnboardingStatusFailedEvent,
  fetchUserOnboardingStatusSucceededEvent,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
