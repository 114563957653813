"use strict";
/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRoleEnum = exports.PostTypeEnum = void 0;
var PostTypeEnum;
(function (PostTypeEnum) {
    PostTypeEnum["IMAGE"] = "IMAGE";
    PostTypeEnum["TEXT"] = "TEXT";
})(PostTypeEnum = exports.PostTypeEnum || (exports.PostTypeEnum = {}));
var UserRoleEnum;
(function (UserRoleEnum) {
    UserRoleEnum["STANDARD"] = "STANDARD";
    UserRoleEnum["ADMIN"] = "ADMIN";
})(UserRoleEnum = exports.UserRoleEnum || (exports.UserRoleEnum = {}));
