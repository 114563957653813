import React from "react";
import { Route, Routes } from "react-router-dom";

import { UserRoleEnum } from "@fourz/appsync-client";

import RequiresRole from "./components/RequiresRole";
import RedirectToRootWhenAuthenticated from "./components/RedirectToRootWhenAuthenticated";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Settings from "./containers/Settings";

import SignIn from "./containers/access/SignIn";
import SignUp from "./containers/access/SignUp";

import TermsAndConditions from "./containers/admin/TermsAndConditions";
import Authorize from "./containers/access/Authorize";
import Create from "./containers/Create";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/create" element={<Create />} />

      <Route
        path="/sign-up"
        element={
          <RedirectToRootWhenAuthenticated>
            <SignUp />
          </RedirectToRootWhenAuthenticated>
        }
      />

      <Route
        path="/sign-in"
        element={
          <RedirectToRootWhenAuthenticated>
            <SignIn />
          </RedirectToRootWhenAuthenticated>
        }
      />
      <Route
        path="/sign-in/:code"
        element={
          <RedirectToRootWhenAuthenticated>
            <SignIn />
          </RedirectToRootWhenAuthenticated>
        }
      />

      <Route
        path="/settings"
        element={
          <RequiresRole requiredRoles={[UserRoleEnum.ADMIN]}>
            <Settings />
          </RequiresRole>
        }
      />

      <Route
        path="/admin/terms-and-conditions"
        element={
          <RequiresRole requiredRoles={[UserRoleEnum.ADMIN]}>
            <TermsAndConditions />
          </RequiresRole>
        }
      />

      <Route
        path="/authorize"
        element={
          <RedirectToRootWhenAuthenticated>
            <Authorize />
          </RedirectToRootWhenAuthenticated>
        }
      />

      {/* Finally, catch all unmatched routes */}
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
