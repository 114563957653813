//import { PostType, PostTypeConnection } from '@fourz/appsync-client';
import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";

export interface FeedState {
  errors: string[] | undefined;
  loading: boolean;
  tokenPosts: Array<ITokenPost>;
  tokens: string[];
  limit: number;
  activeCall: "next" | "prev" | undefined;
}

const initialState: FeedState = {
  errors: undefined,
  loading: false,
  tokenPosts: [],
  tokens: [],
  limit: 6,
  activeCall: undefined,
};

interface ITokenPost {
  readonly token: string;
  readonly post: any;
}

interface IListPostsRequest {
  readonly readForwards: boolean;
  readonly limit: number;
  readonly nextToken?: string;
}

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    resetFeedCommand: (): FeedState => {
      return initialState;
    },

    listNextPostsCommand: (state: FeedState): FeedState => {
      return {
        ...state,
        loading: true,
        activeCall: "next",
      };
    },
    listPreviousPostsCommand: (state: FeedState): FeedState => {
      return {
        ...state,
        loading: true,
        activeCall: "prev",
      };
    },

    dataNotReadEvent: (state: FeedState, _action: Action): FeedState => {
      return {
        ...state,
        loading: false,
      };
    },

    listPostsSucceededReadForwardsTrueEvent: (
      state: FeedState,
      { payload }: PayloadAction<any>
    ): FeedState => {
      const nt = payload.nextToken ?? lastTokenReadForwardTrue;

      return {
        ...state,
        loading: false,
        activeCall: undefined,

        tokenPosts: [
          ...state.tokenPosts,
          ...(payload.posts
            ? payload.posts?.map((p: any) => {
                return { token: nt, post: p };
              })
            : []),
        ],
        // tokenPosts: handleReadForwardTrueTokenPosts(
        //   state.tokensnt
        //   state.tokenPosts,
        //   payload,
        // ),

        tokens: [...state.tokens, nt],
        // tokens: handleReadForwardTrueTokens(state.tokens, payload.nextToken),
      };
    },
    listPostsSucceededReadForwardsFalseEvent: (
      state: FeedState,
      { payload }: PayloadAction<any>
    ): FeedState => {
      const nt = payload.nextToken ?? lastTokenReadForwardFalse;
      return {
        ...state,
        loading: false,
        activeCall: undefined,

        tokenPosts: [
          ...(payload.posts
            ? payload.posts.reverse().map((p: any) => {
                return { token: nt, post: p };
              })
            : []),
          ...state.tokenPosts,
        ],
        // tokenPosts: handleReadForwardFalseTokenPosts(
        //   state.tokens,
        //   state.tokenPosts,
        //   payload,
        // ),

        tokens: [nt, ...state.tokens],
        //tokens: handleReadForwardFalseTokens(state.tokens, payload.nextToken),
      };
    },
    listPostsFailedEvent: (
      state: FeedState,
      { payload }: PayloadAction<string>
    ): FeedState => {
      return {
        ...state,
        loading: false,
        activeCall: undefined,
        errors: state.errors ? [...state.errors, payload] : [payload],
      };
    },
  },
});

const lastTokenReadForwardTrue = "last-token-read-forward-true";
const lastTokenReadForwardFalse = "last-token-read-forward-false";

const handleReadForwardTrueTokens = (
  stateTokens: string[],
  nextToken: string | null | undefined
): string[] => {
  if (stateTokens.length < 3) {
    return [...stateTokens, nextToken ?? lastTokenReadForwardTrue];
  }

  return [...stateTokens.slice(1), nextToken ?? lastTokenReadForwardTrue];
};

const handleReadForwardFalseTokens = (
  stateTokens: string[],
  nextToken: string | null | undefined
): string[] => {
  if (stateTokens.length < 3) {
    return [nextToken ?? lastTokenReadForwardFalse, ...stateTokens];
  }

  return [nextToken ?? lastTokenReadForwardFalse, ...stateTokens.slice(0, -1)];
};

const handleReadForwardTrueTokenPosts = (
  stateTokens: string[],
  stateTokenPosts: ITokenPost[],
  { posts, nextToken }: any
): ITokenPost[] => {
  const tokenPostArray = posts
    ? posts.map((p: any) => {
        const tp: ITokenPost = {
          token: nextToken ?? lastTokenReadForwardTrue,
          post: p,
        };
        return tp;
      })
    : [];

  if (stateTokens.length < 3) {
    return [...stateTokenPosts, ...tokenPostArray];
  }
  const lastToken = stateTokens[2];

  const postsToKeep: ITokenPost[] = stateTokenPosts
    .map((tp: ITokenPost) => {
      if (tp.token !== lastToken) {
        return tp;
      }
    })
    .filter((tp) => tp) as ITokenPost[];

  return [...postsToKeep, ...tokenPostArray];
};

const handleReadForwardFalseTokenPosts = (
  stateTokens: string[],
  stateTokenPosts: ITokenPost[],
  { posts, nextToken }: any
): ITokenPost[] => {
  const tokenPostArray = posts
    ? posts
        .map((p: any) => {
          const tp: ITokenPost = {
            token: nextToken ?? lastTokenReadForwardFalse,
            post: p,
          };
          return tp;
        })
        .reverse()
    : [];
  if (stateTokens.length > 3) {
    return [...tokenPostArray, ...stateTokenPosts];
  }
  const firstToken = stateTokens[0];

  const postsToKeep: ITokenPost[] = stateTokenPosts
    .map((tp: ITokenPost) => {
      if (tp.token !== firstToken) {
        return tp;
      }
    })
    .filter((tp) => tp) as ITokenPost[];

  return [...tokenPostArray, ...postsToKeep];
};

export type { IListPostsRequest };

export { lastTokenReadForwardFalse, lastTokenReadForwardTrue };

export const {
  resetFeedCommand,
  listNextPostsCommand,
  listPreviousPostsCommand,
  listPostsSucceededReadForwardsTrueEvent,
  listPostsSucceededReadForwardsFalseEvent,
  listPostsFailedEvent,
  dataNotReadEvent,
} = feedSlice.actions;

export default feedSlice.reducer;
