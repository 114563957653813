import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { FourzImage } from '../../models/FourzImage';
//import { PostModel } from '../../models/PostModel';

export interface CreatePostState {
  subject: string | undefined;
  topLeft: any | undefined;
  topRight: any | undefined;
  bottomLeft: any | undefined;
  bottomRight: any | undefined;
}

const initialState: CreatePostState = {
  subject: undefined,
  topLeft: undefined,
  topRight: undefined,
  bottomLeft: undefined,
  bottomRight: undefined,
};

const createPostSlice = createSlice({
  name: 'create-post',
  initialState,
  reducers: {
    resetPostCommand: (): CreatePostState => {
      return initialState;
    },

    setCurrentCommand: (
      _state: CreatePostState,
      {
        payload: { subject, topLeft, topRight, bottomLeft, bottomRight },
      }: PayloadAction<any>,
    ): CreatePostState => {
      return {
        subject,
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
      };
    },

    setSubjectCommand: (
      state: CreatePostState,
      { payload }: PayloadAction<string>,
    ): CreatePostState => {
      return {
        ...state,
        subject: payload,
      };
    },

    setTopLeftCommand: (
      state: CreatePostState,
      { payload }: PayloadAction<any>,
    ): CreatePostState => {
      return {
        ...state,
        topLeft: payload,
      };
    },

    setTopRightCommand: (
      state: CreatePostState,
      { payload }: PayloadAction<any>,
    ): CreatePostState => {
      return {
        ...state,
        topRight: payload,
      };
    },

    setBottomLeftCommand: (
      state: CreatePostState,
      { payload }: PayloadAction<any>,
    ): CreatePostState => {
      return {
        ...state,
        bottomLeft: payload,
      };
    },

    setBottomRightCommand: (
      state: CreatePostState,
      { payload }: PayloadAction<any>,
    ): CreatePostState => {
      return {
        ...state,
        bottomRight: payload,
      };
    },
  },
});

export const {
  resetPostCommand,
  setCurrentCommand,
  setSubjectCommand,
  setTopLeftCommand,
  setTopRightCommand,
  setBottomLeftCommand,
  setBottomRightCommand,
} = createPostSlice.actions;

export default createPostSlice.reducer;
