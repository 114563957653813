import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";

import { gql, useQuery } from "@apollo/client";

import {
  getUser,
  GetUserQuery,
  GetUserQueryVariables,
  UserRoleEnum,
} from "@fourz/appsync-client";

import { useAccessContext } from "./AccessContext";

import config from "../config.json";

const NavBar: React.FC = () => {
  const { roles, userId, signOut } = useAccessContext();

  const { loading, error, data } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(gql(getUser), {
    variables: { userId: userId! },
  });

  return (
    <Navbar bg="light" expand="md">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand className="font-weight-bold text-muted">
            {config.core.Stage === "prod"
              ? "Fourz"
              : `Fourzz (${config.core.Stage})`}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname}>
            {roles?.includes(UserRoleEnum.ADMIN) ? (
              <>
                <LinkContainer to="/admin/users">
                  <Nav.Link>users</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/admin/terms-and-conditions">
                  <Nav.Link>terms and conditions</Nav.Link>
                </LinkContainer>
              </>
            ) : (
              <></>
            )}

            {roles ? (
              <>
                <LinkContainer to="/create">
                  <Nav.Link>create</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={signOut}>sign out</Nav.Link>
              </>
            ) : (
              <>
                <LinkContainer to="/sign-up">
                  <Nav.Link>sign up</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/sign-in">
                  <Nav.Link>sign in</Nav.Link>
                </LinkContainer>
              </>
            )}

            {userId ? (
              <>
                {loading ? (
                  <Navbar.Text>loading...</Navbar.Text>
                ) : (
                  <>
                    {error ? (
                      <Navbar.Text>{error.message}</Navbar.Text>
                    ) : (
                      <Navbar.Text>
                        signed in as:{" "}
                        <a href="#login">{`${data?.getUser?.firstname} ${data?.getUser?.lastname}`}</a>
                      </Navbar.Text>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
