import "./SignUp.css";
import React, { FormEvent, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { Form } from "react-bootstrap";



import { useTypedFormFields } from "../../helpers/useTypedFormFields";
import LoaderButton from "../../components/LoaderButton";
import CheckEmail from "../../components/CheckEmail";
import { RootState } from "../../redux/root.reducer";
import { resetAccessCommand, signUpCommand } from "../../redux/access";

interface ISignUpFormProps {
  firstname: string;
  lastname: string;
  email: string;
}

// type SignUpProps = {
//   resetAccessCommandFunc: () => void;
//   isSignUpInProgress: boolean;
//   hasSentEmail: boolean;
//   signUpCommandFunc: (
//     email: string,
//     firstname: string,
//     lastname: string
//   ) => void;

//   emailAlreadyExists: boolean;
// };

const SignUp: React.FC = () => {
  const dispatch = useDispatch();

  const {
    accessState: { errors: es, isSignUpInProgress, hasSentEmail },
    accessTransientState: { emailAlreadyExists, email: eml },
  } = useSelector((state: RootState) => state);

  const { fields, changeHandler } = useTypedFormFields<ISignUpFormProps>({
    firstname: "",
    lastname: "",
    email: "",
  });

  useEffect(() => {
    function onLoad() {
      dispatch(resetAccessCommand());
    }
    onLoad();
  }, [dispatch]);

  const handleSignUpSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(
      signUpCommand({
        email: fields.email,
        firstname: fields.firstname,
        lastname: fields.lastname,
      })
    );
  };

  const validateSignUpForm = () => {
    return (
      fields.email.length > 0 &&
      fields.firstname.length > 0 &&
      fields.lastname.length > 0
    );
  };

  function renderCheckEmail(showAlreadyExists: boolean, email: string) {
    return (
      <div className="SignUp">
        <CheckEmail showAlreadyExists={showAlreadyExists} email={email} />
      </div>
    );
  }

  const renderSignUpForm = () => {
    return (
      <div className="SignUp">
        <div className="container">
          <Form onSubmit={handleSignUpSubmit}>
            <Form.Group className="mb-3" controlId="firstname">
              <Form.Label>firstname</Form.Label>
              <Form.Control
                autoFocus
                type="firstname"
                value={fields.firstname}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group controlId="lastname">
              <Form.Label>lastname</Form.Label>
              <Form.Control
                type="lastname"
                value={fields.lastname}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>email</Form.Label>
              <Form.Control
                type="email"
                value={fields.email}
                onChange={changeHandler}
              />
            </Form.Group>

            <LoaderButton
              //block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isSignUpInProgress}
              disabled={!validateSignUpForm()}
            >
              sign up
            </LoaderButton>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      {hasSentEmail
        ? renderCheckEmail(emailAlreadyExists, fields.email)
        : renderSignUpForm()}
    </>
  );
};

export default SignUp;
