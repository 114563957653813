import "./Authorize.css";
import React, { useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";

import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import {
  federatedSignInCommand,
  handleFederatedSignInCodeCommand,
} from "../../redux/access";
import { RootState } from "../../redux/root.reducer";

type AuthorizeProps = {
  handleFederatedSignInCodeCommandFunc: (code: string) => void;
  federatedSignInCommandFunc: (provider: string) => void;
};

const Authorize: React.FC = () => {
  const dispatch = useDispatch();
  const [signInSuccess, setSignInSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("code"));

  useEffect(() => {
    //https://localhost:3000/authorize?code=50be2312-2766-4c66-8519-b56f1339ef77&state=g37eowerfTMbOaUxSdCCMYyzvGe1iCDB
    const code = searchParams.get("code");
    const errorDescription = searchParams.get("error_description");
    if (code) {
      dispatch(handleFederatedSignInCodeCommand(code));
      setSignInSuccess(true);
    }

    if (errorDescription) {
      if (errorDescription.includes("Already found an entry for username")) {
        setSignInSuccess(false);
        return;
      }
    }
  }, [searchParams]);

  return (
    <div className="SignIn">
      <div className="container">
        <div className="col-sm">
          {signInSuccess ? <p>true</p> : <p>false</p>}
          <Button
            title="Facebook"
            onClick={() => {
              dispatch(federatedSignInCommand("Facebook"));
            }}
          >
            Facebook
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    accessTransientState: {
      email,
      isSignInInProgress,
      //hasSentEmail,
      userDoesNotExist,
      isHandlingSignInCodeInProgress,
      isFetchingIdentityIdInProgress,
    },
    accessState: { hasSentEmail },
  } = state;

  return {
    hasSentEmail,
    isSignInInProgress,
    userDoesNotExist,
    eml: email!,
    isHandlingSignInCodeInProgress,
  };
};

export default Authorize;
