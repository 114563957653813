import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InterestState {
  selectedInterests: string[];
  interests: { name: string; image: string }[];
}

const initialState: InterestState = {
  selectedInterests: [],
  interests: [
    { name: 'fitness', image: '../../images/fitness.png' },
    { name: 'fashion', image: '../../images/fitness.png' },
    { name: 'design', image: '../../images/fitness.png' },

    { name: 'sport', image: '../../images/fitness.png' },
    { name: 'for you', image: '../../images/fitness.png' },
    { name: 'animals', image: '../../images/fitness.png' },

    { name: 'cars', image: '../../images/fitness.png' },
    { name: 'music', image: '../../images/fitness.png' },
    { name: 'travel', image: '../../images/fitness.png' },

    { name: 'advice', image: '../../images/fitness.png' },
    { name: 'architecture', image: '../../images/fitness.png' },
    { name: 'food', image: '../../images/fitness.png' },
  ],
};

const interestSlice = createSlice({
  name: 'interest',
  initialState,
  reducers: {
    resetInterestsCommand: (): InterestState => {
      return initialState;
    },

    interestSelectedCommand: (
      state: InterestState,
      { payload }: PayloadAction<string>,
    ): InterestState => {
      const { selectedInterests: interests } = state;
      if (interests.includes(payload)) {
        return state;
      }
      return {
        ...state,
        selectedInterests: [...interests, payload],
      };
    },

    interestDeselectedCommand: (
      state: InterestState,
      { payload }: PayloadAction<string>,
    ): InterestState => {
      return {
        ...state,
        selectedInterests: state.selectedInterests.filter((i) => i !== payload),
      };
    },
  },
});

export const {
  resetInterestsCommand,
  interestSelectedCommand,
  interestDeselectedCommand,
} = interestSlice.actions;

export default interestSlice.reducer;
