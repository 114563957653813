import { Action, PayloadAction } from "@reduxjs/toolkit";
import { Observable, from, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from "rxjs/operators";
import { combineEpics, Epic } from "redux-observable";

// import {
//   getBaseOsSync,
//   getDeviceId,
//   getSystemVersion,
//   getBuildIdSync,
//   getVersion,
//   getManufacturerSync,
// } from 'react-native-device-info';

import { ISignUpResult } from "amazon-cognito-identity-js";

import { RootState } from "../root.reducer";

import {
  analyticsUpateEndpointCommand,
  analyticsUpateEndpointFailedEvent,
  analyticsUpateEndpointSucceededEvent,
  clearCognitoIdentityCredentialsCommand,
  cognitoIdentityCredentialsClearedEvent,
  federatedSignInCommand,
  federatedSignInCompleteCommand,
  federatedSignInFailedEvent,
  federatedSignInSucceededEvent,
  fetchIdentityIdCommand,
  fetchIdentityIdFailedEvent,
  fetchIdentityIdSucceededEvent,
  handleFederatedSignInCodeCommand,
  handleSignInCodeCommand,
  refreshSessionCommand,
  refreshSessionFailedEvent,
  refreshSessionSucceededEvent,
  resetAccessCommand,
  signInAndStoreSessionCommand,
  signInCommand,
  signInEmailSentEvent,
  signInFailedEvent,
  signInSucceededEvent,
  signOutCommand,
  signOutFailedEvent,
  signOutSucceededEvent,
  signUpCommand,
  signUpFailedEvent,
  updateUserIdentityIdCommand,
  updateUserIdentityIdFailedEvent,
  updateUserIdentityIdSucceededEvent,
  userDoesNotExistEvent,
} from "./access.slice";

import { ISignUp } from "./models/ISignUp";

import { IReduxDependencies } from "../IReduxDependencies";
import { AccessManager } from "./AccessManager";
//import { Constants } from '../../core/Constants';
import { ISignUpParams } from "./models/ISignUpParams";

import {
  fetchUserOnboardingStatusCommand,
  resetOnboardingCommand,
} from "../onboarding/onboarding.slice";

const signUpCommandEpic$: Epic = (
  action$: Observable<PayloadAction<ISignUp>>,
  _rootState$: Observable<RootState>,
  { amplifyAuthAdapter: { authSignUpAsync } }: IReduxDependencies
) => {
  return action$.pipe(
    filter((action) => signUpCommand.match(action)),
    mergeMap((action) => {
      //console.log('action: ', action);
      const {
        payload: { email, firstname, lastname, ...customAttributes },
      } = action;

      const entries = Object.entries(customAttributes);

      const custom: any = {};
      for (let index = 0; index < entries.length; index++) {
        const element = entries[index];
        const name = element[0];
        const value = element[1];
        custom[`custom:${name}`] = value;
      }

      const signUpParams: ISignUpParams = {
        username: email,
        password: Math.random().toString(20).substr(2, 12),
        attributes: {
          given_name: firstname,
          family_name: lastname,
          ...custom,
        },
      };

      return from(authSignUpAsync(signUpParams)).pipe(
        map((_: ISignUpResult) => {
          //console.log('ISignUpResult: ', r);
          return signInAndStoreSessionCommand(true);
          //return { type: 'foo' };
        }),
        catchError((error) => {
          if (error.code === "UsernameExistsException") {
            return of(signInAndStoreSessionCommand(false));
          }

          console.log("error: ", error);
          return of(signUpFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

const signInAndStoreSessionCommandEpic$: Epic = (
  action$: Observable<PayloadAction<boolean>>,
  rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { signInAsync } = new AccessManager(amplifyAuthAdapter, awsConfig);
  return action$.pipe(
    filter((action) => signInAndStoreSessionCommand.match(action)),

    withLatestFrom(rootState$),
    map(([, state]) => {
      const {
        accessTransientState: { email },
      } = state;

      return email;
    }),
    mergeMap((email) => {
      if (email) {
        return from(signInAsync(email!)).pipe(
          map((cognitoUser) => {
            return signInEmailSentEvent(cognitoUser);
          }),
          catchError((error) => {
            //console.log('error: ', error);
            return of(signUpFailedEvent(JSON.stringify(error)));
          })
        );
      } else {
        return of(signUpFailedEvent("email not found"));
      }
    })
  );
};

const signInCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  _rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { signInAsync } = new AccessManager(amplifyAuthAdapter, awsConfig);
  return action$.pipe(
    filter((action) => signInCommand.match(action)),

    mergeMap((action) => {
      const { payload: email } = action;

      return from(signInAsync(email!)).pipe(
        map((cognitoUser) => {
          return signInEmailSentEvent(cognitoUser);
        }),
        catchError((error) => {
          if (error.code === "UserNotFoundException") {
            return of(userDoesNotExistEvent());
          }

          //console.log('error: ', error);
          return of(signInFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

const federatedSignInCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  _rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { federatedSignInAsync } = new AccessManager(
    amplifyAuthAdapter,
    awsConfig
  );
  return action$.pipe(
    filter((action) => federatedSignInCommand.match(action)),

    mergeMap((action) => {
      const { payload: provider } = action;

      return from(federatedSignInAsync(provider!)).pipe(
        map((creds) => {
          //console.log('creds: ', creds);
          // if its the first sign in we might get the ubiqutios "already found"
          // error, we have no way of knowing that here, we have to rely on the
          // ui to inform us if that has happend, or maybe it takes care of
          // initiating the log in again...
          return federatedSignInSucceededEvent(creds);
        }),
        catchError((error) => {
          //console.log('error: ', error);
          return of(federatedSignInFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

const signOutCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  _rootState$: Observable<RootState>,
  { amplifyAuthAdapter: { authSignOutAsync } }: IReduxDependencies
) => {
  return action$.pipe(
    filter((action) => signOutCommand.match(action)),

    mergeMap(() => {
      return from(authSignOutAsync()).pipe(
        mergeMap(() => {
          return of(
            signOutSucceededEvent(),
            // resetUserStateCommand(),
            // resetTermsAndConditionsStateCommand(),
            clearCognitoIdentityCredentialsCommand()
          );
        }),
        catchError((error) => {
          // console.log('error: ', error);
          return of(signOutFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

const clearCognitoIdentityCredentialsCommandEpic$: Epic = (
  action$: Observable<Action>,
  rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { clearCognitoIdentityCredentials } = new AccessManager(
    amplifyAuthAdapter,
    awsConfig
  );

  return action$.pipe(
    filter((action) => clearCognitoIdentityCredentialsCommand.match(action)),

    withLatestFrom(rootState$),

    map(([_, state]) => {
      const {
        accessState: { idToken },
      } = state;

      clearCognitoIdentityCredentials(idToken!);

      return cognitoIdentityCredentialsClearedEvent();
    })
  );
};

const refreshSessionCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { refreshSessionAsync } = new AccessManager(
    amplifyAuthAdapter,
    awsConfig
  );

  return action$.pipe(
    filter((action) => refreshSessionCommand.match(action)),

    withLatestFrom(rootState$),

    map(([_, state]) => {
      const {
        accessState: { cognitoUser, refreshToken },
      } = state;

      return {
        cognitoUser,
        refreshToken,
      };
    }),

    mergeMap((x) => {
      const { cognitoUser, refreshToken } = x;

      return from(refreshSessionAsync(cognitoUser!, refreshToken!)).pipe(
        map((sessiondata) => {
          if (sessiondata) {
            return refreshSessionSucceededEvent(sessiondata);
          }
          return refreshSessionFailedEvent("");
        }),
        catchError((error) => {
          //console.log('error: ', error);
          return of(refreshSessionFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

const handleFederatedSignInCodeCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  _rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { handleFederatedSignInCodeAsync } = new AccessManager(
    amplifyAuthAdapter,
    awsConfig
  );

  return action$.pipe(
    filter((action) => handleFederatedSignInCodeCommand.match(action)),

    mergeMap((action) => {
      return from(handleFederatedSignInCodeAsync(action.payload)).pipe(
        mergeMap((federatedSignIn) => {
          if (federatedSignIn) {
            //console.log('federatedSignIn:', federatedSignIn);
            return of(
              federatedSignInCompleteCommand(federatedSignIn),
              fetchIdentityIdCommand()
            );
          } else {
            return of(federatedSignInFailedEvent(""));
          }
        })
      );
    })
  );
};

const handleSignInCodeCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  rootState$: Observable<RootState>,
  { amplifyAuthAdapter, awsConfig }: IReduxDependencies
) => {
  const { rehydrateContigoUser, sendCustomChallengeAnswerAsync } =
    new AccessManager(amplifyAuthAdapter, awsConfig);

  return action$.pipe(
    filter((action) => handleSignInCodeCommand.match(action)),

    withLatestFrom(rootState$),

    map(([action, state]) => {
      const {
        accessState: { cognitoUser, isSignUpInProgress },
      } = state;

      const { payload } = action;
      //console.log("payload: ", payload);

      if (cognitoUser) {
        const user = rehydrateContigoUser(cognitoUser);
        //console.log("user: ", user);
        return {
          user: user,
          code: payload,
          isSignUpInProgress: isSignUpInProgress,
        };
      } else {
        return { user: undefined, code: payload };
      }
    }),

    mergeMap((x) => {
      const { user, code } = x;
      if (user) {
        return from(sendCustomChallengeAnswerAsync(user, code)).pipe(
          mergeMap((result) => {
            if (result) {
              return of(
                signInSucceededEvent(result),
                fetchIdentityIdCommand(),
                analyticsUpateEndpointCommand(result.preferredUsername),
                fetchUserOnboardingStatusCommand()
              );
            }
            return of(signInFailedEvent(""));
          }),
          catchError((error) => {
            console.log("error: ", error);
            return of(signInFailedEvent(JSON.stringify(error)));
          })
        );
      } else {
        return of(signInFailedEvent(""));
      }
    })
  );
};

const fetchIdentityIdCommandEpic$: Epic = (
  action$: Observable<Action>,
  rootState$: Observable<RootState>,
  { awsConfig, amplifyAuthAdapter, graphQlService }: IReduxDependencies
) => {
  const { fetchIdentityIdAsync } = new AccessManager(
    amplifyAuthAdapter,
    awsConfig
  );

  return action$.pipe(
    filter((action) => fetchIdentityIdCommand.match(action)),

    withLatestFrom(rootState$),

    map(([_, state]) => {
      const {
        accessState: { idToken, accessToken },
      } = state;

      return {
        idToken: idToken!,
        accessToken: accessToken!,
      };
    }),

    mergeMap((action) => {
      const { idToken } = action;

      return from(fetchIdentityIdAsync(idToken)).pipe(
        mergeMap((identityId) => {
          return of(
            fetchIdentityIdSucceededEvent(identityId),
            updateUserIdentityIdCommand(identityId)
          );
        }),
        catchError((error) => {
          //console.log('error: ', error);
          return of(fetchIdentityIdFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

const updateUserIdentityIdCommandEpic$: Epic = (
  action$: Observable<PayloadAction<string>>,
  rootState$: Observable<RootState>,
  { awsConfig, amplifyAuthAdapter, graphQlService }: IReduxDependencies
) => {
  return action$.pipe(
    filter((action) => updateUserIdentityIdCommand.match(action)),

    withLatestFrom(rootState$),

    map(([{ payload }, state]) => {
      const {
        accessState: { userId },
      } = state;

      return {
        userId: userId!,
        identityId: payload!,
      };
    }),

    mergeMap((action) => {
      const { userId, identityId } = action;

      return from(
        graphQlService.updateUserIdentityIdAsync(userId, identityId)
      ).pipe(
        mergeMap((_userId) => {
          return of(updateUserIdentityIdSucceededEvent("identityId"));
        }),
        catchError((error) => {
          console.log("error: ", error);
          return of(updateUserIdentityIdFailedEvent(JSON.stringify(error)));
        })
      );
    })
  );
};

// const analyticsUpateEndpointCommandEpic$: Epic = (
//   action$: Observable<PayloadAction<string>>,
//   _rootState$: Observable<RootState>,
//   {
//     analyticsAdapter: { analyticsUpdateEndpoint },
//     storage,
//   }: IReduxDependencies,
// ) => {
//   return action$.pipe(
//     filter((action) => analyticsUpateEndpointCommand.match(action)),

//     mergeMap((action) => {
//       const { payload } = action;
//       return from(storage.getItem(Constants.PUSH_REGISTRATION_MODEL)).pipe(
//         mergeMap((registrationJson: any) => {
//           let attrs: any = {};
//           const demographic = {
//             AppVersion: getVersion(),
//             Make: getManufacturerSync(),
//             Model: getDeviceId(),
//             ModelVersion: getBuildIdSync(),
//             Platform: getBaseOsSync(),
//             PlatformVersion: getSystemVersion(),
//             //Timezone:getTimezone()
//           };
//           if (registrationJson) {
//             const { service, token } = JSON.parse(registrationJson);
//             attrs = {
//               UserId: payload,
//               Address: token,
//               OptOut: 'NONE',
//               ChannelType: service,
//               Demographic: demographic,
//             };
//           } else {
//             attrs = {
//               UserId: payload,
//               Demographic: demographic,
//             };
//           }
//           return from(analyticsUpdateEndpoint(attrs)).pipe(
//             mergeMap((_) => {
//               // console.log('updateEndpointResponse:', updateEndpointResponse);
//               return of(analyticsUpateEndpointSucceededEvent());
//             }),
//           );
//         }),
//         catchError((error) => {
//           console.log('error: ', error);
//           return of(analyticsUpateEndpointFailedEvent(JSON.stringify(error)));
//         }),
//       );
//     }),
//   );
// };

const resetAccessCommandEpic$: Epic = (action$: Observable<Action>) => {
  return action$.pipe(
    filter((action) => resetAccessCommand.match(action)),
    map((_) => {
      return resetOnboardingCommand();
    })
  );
};

export default combineEpics(
  signUpCommandEpic$,
  signInAndStoreSessionCommandEpic$,
  signInCommandEpic$,
  federatedSignInCommandEpic$,
  signOutCommandEpic$,
  clearCognitoIdentityCredentialsCommandEpic$,
  refreshSessionCommandEpic$,
  handleSignInCodeCommandEpic$,
  handleFederatedSignInCodeCommandEpic$,
  fetchIdentityIdCommandEpic$,
  updateUserIdentityIdCommandEpic$,
  //analyticsUpateEndpointCommandEpic$,
  resetAccessCommandEpic$
);
