import "./CreatePost.css";

import React, { FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";

import {
  createPost,
  CreatePostMutation,
  CreatePostMutationVariables,
  PostTypeEnum,
} from "@fourz/appsync-client";

import { useTypedFormFields } from "../helpers/useTypedFormFields";

import { useAccessContext } from "./AccessContext";
import LoaderButton from "./LoaderButton";

interface ICreatePostFormProps {
  subject: string;
  type: PostTypeEnum;
}

const CreatePost: React.FC = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { userId } = useAccessContext();

  const { fields, changeHandler } = useTypedFormFields<ICreatePostFormProps>({
    subject: "",
    type: PostTypeEnum.IMAGE,
  });

  const [createPostAsync, { error, data }] = useMutation<
    CreatePostMutation,
    CreatePostMutationVariables
  >(gql(createPost));

  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingForm(true);

    const r = await createPostAsync({
      variables: {
        input: {
          userId: userId!,
          subject: fields.subject,
          type: fields.type,
        },
      },
      // refetchQueries: [
      //   // this is declared twice, in the list as well...
      //   { query: gql(getPayments), variables: { userId: user?.id } },
      // ],
    });

    console.log("r: ", r);

    setIsSubmittingForm(false);
  };

  const validateForm = () => {
    return fields.subject.length > 3;
  };

  return (
    <div className="CreatePost">
      <div className="container">
        {error ? <p>Oh no! {error.message}</p> : null}
        {data && data.createPost ? (
          <>
            <p>{JSON.stringify(data, null, 2)}</p>
          </>
        ) : null}

        <Form onSubmit={handleOnSubmitAsync}>
          <Form.Group controlId="subject">
            <Form.Label>subject</Form.Label>
            <Form.Control
              autoFocus
              type="subject"
              value={fields.subject}
              onChange={changeHandler}
            />
          </Form.Group>

          <Form.Group controlId="type">
            <Form.Label>Dis</Form.Label>
            <Form.Select id="type" value={fields.type} onChange={changeHandler}>
              <option value={PostTypeEnum.IMAGE}>image</option>
              <option value={PostTypeEnum.TEXT}>text</option>
            </Form.Select>
          </Form.Group>

          <LoaderButton
            size="lg"
            type="submit"
            variant="success"
            isLoading={isSubmittingForm}
            disabled={!validateForm()}
          >
            create post
          </LoaderButton>
        </Form>
      </div>
    </div>
  );
};

export default CreatePost;
