import { Action } from '@reduxjs/toolkit';
import { Observable, from, of, delay } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';

import { RootState } from '../root.reducer';

import {
  fetchUserOnboardingStatusCommand,
  fetchUserOnboardingStatusFailedEvent,
  fetchUserOnboardingStatusSucceededEvent,
} from './onboarding.slice';

import { IReduxDependencies } from '../IReduxDependencies';

const fetchUserOnboardingStatusCommandEpic$: Epic = (
  action$: Observable<Action>,
  rootState$: Observable<RootState>,
  { graphQlService: userRepository }: IReduxDependencies,
) => {
  return action$.pipe(
    filter((action) => fetchUserOnboardingStatusCommand.match(action)),

    withLatestFrom(rootState$),

    map(([_, state]) => {
      const {
        accessState: { userId },
        onboardingState: { userOnboardingStatus },
      } = state;

      return {
        userId: userId!,
        currentOnboardingStatus: userOnboardingStatus,
      };
    }),

    mergeMap(({ userId, currentOnboardingStatus }) => {
      return from(userRepository.getUserOnboardingStatusAsync(userId)).pipe(
        mergeMap((result) => {
          // we fetch this when a user logs in, if that user has just been
          // created then the return value will be undefined, in which case
          // we wait a second and then dispatch the message to get this again
          // we keep fetching every second until the value of currentOnboardingStatus
          //  has changed.

          // console.log('currentOnboardingStatus: ', currentOnboardingStatus);
          // console.log('result: ', result);

          if (result === currentOnboardingStatus) {
            // console.log('fetching again');
            return from(Promise.resolve()).pipe(
              delay(1000),
              mergeMap((_) => {
                return of(fetchUserOnboardingStatusCommand());
              }),
            );
          }
          // console.log('returning result');
          return of(fetchUserOnboardingStatusSucceededEvent(result));
        }),
        catchError((error) => {
          // console.log('error: ', error);
          return of(
            fetchUserOnboardingStatusFailedEvent(JSON.stringify(error)),
          );
        }),
      );
    }),
  );
};

export default combineEpics(fetchUserOnboardingStatusCommandEpic$);
