import "./FileUpload.css";
import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Form } from "react-bootstrap";

import { API, Storage } from "aws-amplify";

import LoaderButton from "./LoaderButton";
import { useAccessContext } from "./AccessContext";
import { S3ProviderPutConfig, StorageAccessLevel } from "@aws-amplify/storage";

import config from "../config.json";

const FileUpload: React.FC = () => {
  const { userId } = useAccessContext();
  const file = useRef<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [storageAccessLevel, setStorageAccessLevel] =
    useState<StorageAccessLevel>("private");

  const MAX_ATTACHMENT_SIZE = 5000000;

  const handleSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (file.current && file.current.size > MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${MAX_ATTACHMENT_SIZE / 1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      // const attachment = file.current
      //   ? await s3UploadAsync(file.current)
      //   : null;
      const attachment = file.current
        ? await moderatedImageUploadAsync(file.current)
        : null;

      console.log("attachment: ", attachment);
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }

    // Storage.list("", { level: storageAccessLevel }) // for listing ALL files without prefix, pass '' instead
    //   .then((result) => console.log(result))
    //   .catch((err) => console.log(err));
  };

  // const moderatedImageUpload = (): void => {
  //   console.log(config);
  //   API.post(config.ui.ApiName, "/moderated", {
  //     body: {
  //       key: "key",
  //       mimeType: "mimeType",
  //       imageb64: "imageb64",
  //     },
  //   })
  //     .then((response) => {
  //       const { data } = response;
  //       console.log(response);
  //       // fields.id = data.id;
  //       // fields.copy = data.copy;
  //       // setLatest(data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };

  const moderatedImageUploadAsync = async (file: File): Promise<string> => {
    const filename = `profile-images/${Date.now()}-${userId}.${file.name
      .split(".")
      .pop()}`;

    //console.log("file: ", file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      //me.modelvalue = reader.result;
      //console.log(reader.result);
      const s = (reader.result as string);
      const parts = s.split(",");
      const response = await API.post(config.iu.ApiName, "/moderated", {
        body: {
          key: filename,
          mimeType: file.type,
          imageb64: encodeURIComponent(parts[1] as string),
          level: storageAccessLevel,
        },
      });
      console.log("response: ", response);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };

    return filename;
  };

  const s3UploadAsync = async (file: File): Promise<string> => {
    const filename = `profile-images/${Date.now()}-${userId}.${file.name
      .split(".")
      .pop()}`;

    console.log("file: ", file);

    const config: S3ProviderPutConfig = {
      level: storageAccessLevel,
      contentType: file.type,
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    };
    const stored = await Storage.put(filename, file, config);

    console.log("stored: ", stored);

    return filename;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    file.current = event.target.files[0];
  };

  const handleDropDownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    console.log(value);

    switch (value) {
      case "private":
        setStorageAccessLevel("private");
        break;
      case "protected":
        setStorageAccessLevel("protected");
        break;
      case "public":
        setStorageAccessLevel("public");
        break;
    }
  };

  return (
    <div className="FileUpload">
      <div className="container">
        <span>File Upload</span>
        <Form onSubmit={async (e) => await handleSubmitAsync(e)}>
          <Form.Group controlId="file">
            <Form.Label>Attachment</Form.Label>
            <Form.Control onChange={handleFileChange} type="file" />
          </Form.Group>
          <Form.Group controlId="type">
            <Form.Label>Protection Level</Form.Label>
            <Form.Select id="type" onChange={handleDropDownChange}>
              <option value="private">private</option>
              <option value="protected">protected</option>
              <option value="public">public</option>
            </Form.Select>
          </Form.Group>
          <LoaderButton
            //block
            type="submit"
            size="lg"
            variant="primary"
            isLoading={isLoading}
          >
            create
          </LoaderButton>
        </Form>
      </div>
    </div>
  );
};

export default FileUpload;
