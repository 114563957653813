import "./Home.css";
import React from "react";

import { Button } from "react-bootstrap";

import { useMutation, gql, useQuery, useLazyQuery } from "@apollo/client";
import {
  updateUserInterests,
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables,
  updateUserDetails,
  UpdateUserInterestsMutation,
  UpdateUserInterestsMutationVariables,
  getUserInitialImage,
  GetUserInitialImageQuery,
  GetUserInitialImageQueryVariables,
  updateUsername,
  UpdateUsernameMutation,
  UpdateUsernameMutationVariables,
  AcceptTermsAndConditionsMutation,
  acceptTermsAndConditions,
  AcceptTermsAndConditionsMutationVariables,
} from "@fourz/appsync-client";

import { useAccessContext } from "../components/AccessContext";
import FileUpload from "../components/FileUpload";
import CreatePost from "../components/CreatePost";
import ImagePost from "../components/ImagePost";
import ImagedDisplay from "../components/ImagedDisplay";
import PostList from "../components/PostList";
import UserPosts from "../components/UserPosts";

const Home: React.FC = () => {
  const { userId, identityId } = useAccessContext();

  const [updateUserDetailsAsync] = useMutation<
    UpdateUserDetailsMutation,
    UpdateUserDetailsMutationVariables
  >(gql(updateUserDetails), {
    variables: {
      input: {
        userId: userId!,
        dateOfBirth: new Date(1981, 1, 2).toISOString(),
        gender: "grd1",
        location: "loc1",
      },
    },
  });

  const [updateUsernameAsync] = useMutation<
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables
  >(gql(updateUsername), {
    variables: {
      input: {
        userId: userId!,
        username: "foo1",
      },
    },
  });

  const [updateUserInterestsAsync] = useMutation<
    UpdateUserInterestsMutation,
    UpdateUserInterestsMutationVariables
  >(gql(updateUserInterests), {
    variables: {
      input: {
        userId: userId!,
        interests: ["foo", "bar"],
      },
    },
  });

  //[loadGreeting, { called, loading, data }]
  const [getUserInitialImageAsync, { error, data }] = useLazyQuery<
    GetUserInitialImageQuery,
    GetUserInitialImageQueryVariables
  >(gql(getUserInitialImage), {
    variables: {
      userId: userId!,
    },
  });


  const [acceptTermsAndConditionsAsync, { error: a, data: b, loading }] =
    useMutation<
      AcceptTermsAndConditionsMutation,
      AcceptTermsAndConditionsMutationVariables
    >(gql(acceptTermsAndConditions), {
      variables: {
        input: {
          userId: userId!,
          termsAndConditionsId: "termsAndConditionsWithAcceptFlag?.id"!,
        },
      },
    });

  return (
    <>
      {userId && true ? (
        <>
          <Button
            onClick={async () => {
              //await acceptTermsAndConditionsAsync();
              //await updateUserDetailsAsync();
              // await updateUserInterestsAsync();
              //await getUserInitialImageAsync();
              await updateUsernameAsync();
            }}
          >
            update user deets
          </Button>

          <hr></hr>
          {userId}
          {/* <UserPosts></UserPosts> */}
          {/*  <ImagePost></ImagePost>
          <ImagedDisplay></ImagedDisplay> */}
          {/* <PostList></PostList> */}
          <FileUpload></FileUpload>
          {/* <CreatePost></CreatePost> */}
        </>
      ) : (
        <></>
      )}
      <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
        <div className="col-md-5 p-lg-5 mx-auto my-5">
          <h1 className="display-4 font-weight-normal">Punny headline</h1>
          <p className="lead font-weight-normal">
            And an even wittier subheading to boot. Jumpstart your marketing
            efforts with this example based on Apple's marketing pages.
          </p>
          <a className="btn btn-outline-secondary" href="/">
            Coming soon
          </a>
        </div>
        <div className="product-device box-shadow d-none d-md-block"></div>
        <div className="product-device product-device-2 box-shadow d-none d-md-block"></div>
      </div>
    </>
  );
};

export default Home;
