import { ReactNode, createContext, useContext } from "react";


import { connect, useDispatch,useSelector } from "react-redux";
import { signOutCommand } from "../redux/access";
import { RootState } from "../redux/root.reducer";

interface IAccessContextProps {
  roles: string[] | undefined;
  userId: string | undefined;
  identityId: string | undefined;
  signOut: () => void;
}

const AccessContext = createContext<IAccessContextProps>({
  roles: undefined,
  userId: undefined,
  identityId: undefined,
  signOut: () => console.warn("no signOut provider."),
});

interface IAccessContextProviderProps {
  children: ReactNode;
}

const AccessContextProvider: React.FC<IAccessContextProviderProps> = ({

  children,
}) => {
  const dispatch = useDispatch();

  const {
    accessState: { roles, userId, identityId, isSignUpInProgress },
    //onboardingState: { showOnboarding, nextScreen },
  } = useSelector((state: RootState) => state);

  const value = {
    roles: roles,
    userId: userId,
    identityId:identityId,
    signOut: () => dispatch(signOutCommand()),
  };

  return (
    <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
  );
};

const useAccessContext = () => useContext(AccessContext);

export { useAccessContext, AccessContext };

export default AccessContextProvider;
